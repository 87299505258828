import { keyframes } from "@emotion/react";

/**
 * Animation from animate.css
 */
export const backInUp = keyframes`
  0% {
    // transform: translateY(1200px) scale(0.7);
    // opacity: 0.7;
    transform: translateY(200px);
    opacity: 1;
  }

  // 80% {
  //   transform: translateY(0px) scale(0.7);
  //   opacity: 0.7;
  // }

  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

/**
 * Animation from animate.css
 */
export const backOutDown = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }

  // 20% {
  //   transform: translateY(0px) scale(0.7);
  //   opacity: 0.7;
  // }

  100% {
    // transform: translateY(700px) scale(0.7);
    // opacity: 0.7;
    transform: translateY(200px);
    opacity: 1;
    height: 0; // fix to make footer no height
  }
`;

/**
 * Animation from animate.css
 */
export const bounceInUp = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0) scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, 10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, -5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

/**
 * Animation from animate.css
 */
export const bounceInDown = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0) scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0) scaleY(0.9);
  }

  75% {
    transform: translate3d(0, -10px, 0) scaleY(0.95);
  }

  90% {
    transform: translate3d(0, 5px, 0) scaleY(0.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
`;

/**
 * Animation from animate.css
 */
export const bounceOutUp = keyframes`
  20% {
    transform: translate3d(0, -10px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0) scaleY(3);
  }
`;

/**
 * Animation from animate.css
 */
export const bounceOutDown = keyframes`
  20% {
    transform: translate3d(0, 10px, 0) scaleY(0.985);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0) scaleY(0.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0) scaleY(3);
  }
`;

export const rotate180 = keyframes`
  from {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(-90deg);
  }
`;

export const rotate180Backwards = keyframes`
  from {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(90deg);
  }
`;

export const moveRight = keyframes`
  from {
    right: 0;
  }
  to {
    right: -5rem;
  }
`;

export const moveRightBackwards = keyframes`
  from {
    right: -5rem;
  }
  to {
    right: 0;
  }
`;
