import React, {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import firebase from "firebase/compat/app";

import StateContext from "./State";
import Loading from "../components/Loading";
import RoomError from "../components/room/RoomError";
import * as firestore from "../firebase/firestore";
import * as types from "../utils/types";

const removeParticipantFromFirestoreRoom = async (
  room: string,
  clientId: string
) => {
  await firestore.updateRoom(room, {
    [`activeParticipants.${clientId}`]: firebase.firestore.FieldValue.delete(),
  });
};

const addParticipantToFirestoreRoom = async (
  room: string,
  clientId: string,
  displayName: string,
  userId?: string,
  isAnonymous?: boolean,
  photoURL?: string
) => {
  const participant: types.Firestore.WebRoomParticipant = {
    clientId: clientId,
    deviceType: "web",
    grapics: [],
    displayName: displayName,
  };
  if (userId) participant.uid = userId;
  if (isAnonymous) participant.isAnonymous = isAnonymous;
  if (photoURL) participant.photoURL = photoURL;

  await firestore.updateRoom(room, {
    [`activeParticipants.${clientId}`]: participant,
  });
};

const WebRoomContext = createContext<
  | {
      room: types.Firestore.WebRoom;
      participants: types.State.RoomParticipant[];
      setParticipants: React.Dispatch<
        React.SetStateAction<types.State.RoomParticipant[]>
      >;
    }
  | undefined
>(undefined);

export const WebRoomContextHolder = (props: { children: React.ReactNode }) => {
  const params = useParams<{ room: string }>();
  const state = useContext(StateContext);
  const { authUser, clientId, placeholderUserName, user, userName } = state;
  const userId = authUser?.uid;
  const isAnonymous = authUser?.isAnonymous;
  const displayName =
    user?.displayName || user?.email || userName || placeholderUserName;
  const photoURL = user?.photoURL;

  const [webRoom, setWebRoom] = useState<types.Firestore.WebRoom>();
  const [didLoad, setDidLoad] = useState(false);

  const [participants, setParticipants] = useState<
    types.State.RoomParticipant[]
  >([]);

  // Fetch Firestore Room
  useEffect(() => {
    // TODO: I should be able to do this simpler
    let unsubscribe: () => void;
    const asyncEffect = async () => {
      const webRoom = await firestore.getRoom(params.room);

      if (!webRoom) {
        setDidLoad(true);
        return;
      }

      if (!webRoom.activeParticipants[clientId]) {
        // Put my ID in participants list on mount
        await addParticipantToFirestoreRoom(
          params.room,
          clientId,
          displayName,
          userId,
          isAnonymous,
          photoURL
        );
      }

      unsubscribe = firestore.onRoomSnapshot(params.room, (webRoom) => {
        if (webRoom) {
          setWebRoom(webRoom);

          // when  we get a room snapshot, the activeParticipants may have changed
          // so we need to compare it to our participant state
          setParticipants((prevParticipants) => {
            let newParticipants = [...prevParticipants];
            Object.values(webRoom.activeParticipants).forEach((participant) => {
              // index of this room participant
              // the participant will always be v2 since it is coming from activeParticipants
              const index = newParticipants.findIndex(
                (newParticipant) =>
                  newParticipant.participant?.clientId === participant.clientId
              );
              if (index > -1) {
                // this is an existing participant
                newParticipants[index] = {
                  ...newParticipants[index],
                  participant,
                };
              } else {
                // this is a new participant, we can just add it
                newParticipants.push({
                  participant,
                });
              }

              // now we filter out any participant that doesnt exists in activeParticipants anymore
              newParticipants = newParticipants.filter(
                (newParticipant) =>
                  !(
                    newParticipant.participant &&
                    !webRoom.activeParticipants[
                      newParticipant.participant.clientId
                    ]
                  )
              );
            });
            return newParticipants;
          });
        }
        setDidLoad(true);
      });
    };

    asyncEffect();

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [params.room, clientId, displayName, userId, isAnonymous, photoURL]);

  // Remove my ID from participants list on unmount
  useEffect(() => {
    return () => {
      if (!params.room || !clientId) return;
      removeParticipantFromFirestoreRoom(params.room, clientId);
    };
  }, [params.room, clientId]);

  // Remove my ID from participants list on unload
  useBeforeunload(() => {
    removeParticipantFromFirestoreRoom(params.room, clientId);
  });

  // Should we kick user when being removed from activeParticipants?
  useEffect(() => {
    if (didLoad && !webRoom?.activeParticipants[clientId]) {
      console.log("clientId not in activeParticipants - should we kick user?");
    }
  }, [didLoad, webRoom, clientId]);

  return (
    <Fragment>
      {didLoad && webRoom?.streamingMetadata ? (
        <WebRoomContext.Provider
          value={{
            room: webRoom,
            participants,
            setParticipants,
          }}
        >
          {props.children}
        </WebRoomContext.Provider>
      ) : didLoad ? (
        <RoomError reason="not_found" />
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};

export default WebRoomContext;
