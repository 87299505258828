import { useContext, useEffect, useState } from "react";
import TwilioContext from "../contexts/Twilio";

export type RoomStateType = "disconnected" | "connected" | "reconnecting";

/**
 * Get the state of the room
 */
const useRoomState = () => {
  const { room } = useContext(TwilioContext);
  const [state, setState] = useState<RoomStateType>("disconnected");

  useEffect(() => {
    const setRoomState = (...params: any) => {
      const newState = (room?.state || "disconnected") as RoomStateType;
      setState(newState);
    };
    setRoomState();

    room?.on("disconnected", setRoomState);
    room?.on("reconnecting", setRoomState);
    room?.on("reconnected", setRoomState);

    return () => {
      room?.off("disconnected", setRoomState);
      room?.off("reconnecting", setRoomState);
      room?.off("reconnected", setRoomState);
    };
  }, [room]);

  return state;
};

export default useRoomState;
