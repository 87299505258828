const developmentConfig = {
  apiKey: "AIzaSyAHLnlbSD9IAiC1G4XThYw3U8MgfYAB_eU",
  authDomain: "staging.app.grapic.co",
  databaseURL: "https://clipspace-abed2.firebaseio.com",
  projectId: "clipspace-abed2",
  storageBucket: "clipspace-abed2.appspot.com",
  messagingSenderId: "1038075870472",
  appId: "1:1038075870472:web:e9860ce0fcd981bab380bd",
  measurementId: "G-N906NPQDNM",
};

const productionConfig = {
  apiKey: "AIzaSyDYnl1MkJo6JxY4RtX5zzGmwaYwPFrSe6o",
  authDomain: "app.grapic.co",
  databaseURL: "https://grapicapp.firebaseio.com",
  projectId: "grapicapp",
  storageBucket: "grapicapp.appspot.com",
  messagingSenderId: "798281181722",
  appId: "1:798281181722:web:fc3c390445ce2ec5a2bfa3",
  measurementId: "G-PZ0304C50P",
};

export const appConfig =
  process.env.REACT_APP_RELEASE_ENV === "production"
    ? productionConfig
    : developmentConfig;

const developmentGoogleOAuthCliendId =
  "1038075870472-p7er4409bvdsgml98and8r7j6k71a8ju.apps.googleusercontent.com";

const productionGoogleOAuthCliendId =
  "798281181722-mdnlqh3n3rgd8ogapbgp2s59qsm0q6uq.apps.googleusercontent.com";

export const GoogleOAuthCliendId =
  process.env.REACT_APP_RELEASE_ENV === "production"
    ? productionGoogleOAuthCliendId
    : developmentGoogleOAuthCliendId;
