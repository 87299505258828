import { useContext, useEffect, useState } from "react";
import * as twilio from "twilio-video";
import useParticipants from "./useParticipants";
import TwilioContext from "../contexts/Twilio";

type TrackPublication =
  | twilio.LocalTrackPublication
  | twilio.RemoteTrackPublication;

const useAllPublications = () => {
  const { room } = useContext(TwilioContext);
  const participants = useParticipants();
  const [publications, setPublications] = useState<TrackPublication[]>([]);

  const publicationAdded = (publication: TrackPublication) =>
    setPublications((prevPublications) => [...prevPublications, publication]);

  const publicationRemoved = (publication: TrackPublication) =>
    setPublications((prevPublications) =>
      prevPublications.filter((p) => p !== publication)
    );

  useEffect(() => {
    room?.on(
      "participantDisconnected",
      (participant: twilio.RemoteParticipant) =>
        participant.tracks.forEach((publication) =>
          publicationRemoved(publication)
        )
    );
  }, [room]);

  useEffect(() => {
    participants.forEach((participant) => {
      (Array.from(participant.tracks.values()) as TrackPublication[]).forEach(
        publicationAdded
      );

      participant.on("trackPublished", publicationAdded);
      participant.on("trackUnpublished", publicationRemoved);
    });
    return () => {
      participants.forEach((participant) => {
        participant.off("trackPublished", publicationAdded);
        participant.off("trackUnpublished", publicationRemoved);
      });
    };
  }, [participants]);

  return publications;
};

export default useAllPublications;
