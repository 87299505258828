export const HOME = `/`;
export const ROOM_BASE = `/room`;
export const ROOM = `/room/:room`;
export const EMBED_ROOM_BASE = `/embed`;
export const EMBED_ROOM = `/embed/:room`;
export const EMBED_FIGMA_ROOM_BASE = `/figmaEmbed`;
export const EMBED_FIGMA_ROOM = `/figmaEmbed/:room`;
export const SETTINGS = `/settings`;
export const GRAPICS = `/grapics`;
export const GRAPIC_DETAILS = `/grapics/:grapic`;
export const NEW_GRAPIC = `/new`;
export const NEW_GRAPIC_EMBED = `/new/embed`;
export const NEW_GRAPIC_EMBED_FIGMA = `/new/figmaEmbed`;

export const QUERY_ROOM = `room`;
export const QUERY_WEB_USER = `web_user`;
export const QUERY_USER = `user`;
export const QUERY_SIGN_IN_REDIRECT = "redirect";
export const QUERY_QR_CREATOR = `qr_creator`;
export const QUERY_AUTO_SIGN_IN = `authenticate`;
export const QUERY_AUTO_SIGN_IN_ANONYMOUS = `anonymous`;

export const baseUrl =
  process.env.REACT_APP_RELEASE_ENV === "production"
    ? "https://app.grapic.co/"
    : window.location.origin;

export const dynamicLinkDomain =
  process.env.REACT_APP_RELEASE_ENV === "production"
    ? "link.grapic.co"
    : "staging.link.grapic.co";

export const iosBundleId =
  process.env.REACT_APP_RELEASE_ENV === "production"
    ? "co.grapic.app"
    : "co.grapic.app.staging";

export const termsOfServiceUrl = "https://www.grapic.co/terms/";
export const privacyPolicyUrl = "https://www.grapic.co/privacy/";
