import React, { useContext } from "react";
import { SliderButton } from "@typeform/embed-react";
import StateContext from "../contexts/State";

const TYPEFORM_ID = "oh6zaOWD";

const TypeformButton = (props: { children: React.ReactNode }) => {
  const state = useContext(StateContext);

  const hiddenFields: { source: string; email?: string } = {
    source: "app.grapic.co",
  };
  if (state.authUser?.email) {
    hiddenFields.email = state.authUser.email;
  }

  return (
    <SliderButton
      id={TYPEFORM_ID}
      hidden={hiddenFields}
      as="div"
      enableSandbox={process.env.REACT_APP_RELEASE_ENV !== "production"}
    >
      {props.children}
    </SliderButton>
  );
};

export default TypeformButton;
