import * as types from "./types";
import * as utils from "./utils";
import * as figma from "../plugins/figma";

const PRODUCTION_APP_ID = "iklwr1j5";
// const TEST_APP_ID = "xq0pavs8";

export const getAppId = () =>
  process.env.REACT_APP_RELEASE_ENV === "production"
    ? PRODUCTION_APP_ID
    : PRODUCTION_APP_ID;

export const show = () => window.Intercom && window.Intercom("show");

export const hide = () => window.Intercom && window.Intercom("hide");

export const trackEvent = (tag: string | undefined, metadata?: any) =>
  window.Intercom && window.Intercom("trackEvent", tag, metadata);

interface GrapicIntercomSettings extends Intercom_.IntercomSettings {
  /**
   * The account creation date from Firebase Authentication.
   * Should be a a unix timestamp in seconds.
   */
  account_created_at: number | undefined;
}

const update = (param: GrapicIntercomSettings) =>
  window.Intercom &&
  window.Intercom("update", {
    ...param,
    // @ts-ignore
    environment: process.env.REACT_APP_RELEASE_ENV || "staging",
  });

export const updateUser = (authUser: types.AuthenticationUser) => {
  if (!authUser) return;

  const unixTimeStampCreationTime = authUser.metadata.creationTime
    ? Math.floor(new Date(authUser.metadata.creationTime).getTime() / 1000)
    : undefined;

  // TODO: don't put undefined here
  const userUpdate = {
    user_id: authUser.uid || undefined,
    email: authUser.email || undefined,
    name: authUser.displayName || undefined,
    // letting intercom set signed up at itself
    // created_at: unixTimeStampCreationTime || undefined,
    // instead setting this custom attribute
    account_created_at: unixTimeStampCreationTime || undefined,
  };

  update(userUpdate);
};

export const shouldHideIntercom = () =>
  utils.isMobile() || utils.isEmbed() || figma.isProbablyFigma();

export const signOutAndReboot = () => {
  window.Intercom && window.Intercom("shutdown");
  window.Intercom &&
    window.Intercom("boot", {
      app_id: getAppId(),
      hide_default_launcher: shouldHideIntercom(),
    });
};
