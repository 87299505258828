import { useEffect, useState } from "react";
import * as twilio from "twilio-video";

const useTrack = (
  publication:
    | twilio.LocalTrackPublication
    | twilio.RemoteTrackPublication
    | undefined
) => {
  const [track, setTrack] = useState(publication && publication.track);

  useEffect(() => {
    // Reset the track when the 'publication' variable changes.
    setTrack(publication && publication.track);

    if (publication) {
      const removeTrack = () => setTrack(null);

      publication.on("subscribed", setTrack);
      publication.on("unsubscribed", removeTrack);
      return () => {
        publication.off("subscribed", setTrack);
        publication.off("unsubscribed", removeTrack);
      };
    }
  }, [publication]);

  return track;
};

export default useTrack;
