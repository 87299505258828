/** @jsxImportSource @emotion/react */

import { useContext } from "react";
import { css } from "@emotion/react";

import { RoomButton } from "./roomAtoms";
import WebRoomContext from "../../contexts/WebRoom";
import * as analytics from "../../firebase/analytics";
import Share from "../../images/icons/Share";

interface ShareButtonProps {
  className?: string;
  disabled?: boolean;
  sharingUrl: string;
  sharingTitle?: string;
  sharingText?: string;
  buttonText?: string;
  onShare?: () => void;
  onError?: () => void;
}

const ShareButton = ({
  disabled,
  sharingUrl,
  sharingTitle = "Grapic",
  sharingText = "Join my Grapic room",
  buttonText = "Share",
  onShare,
  onError,
  ...rest
}: ShareButtonProps) => {
  const webRoom = useContext(WebRoomContext);

  const onSuccess = () => {
    if (webRoom?.room.id) {
      analytics.logShareRoomEvent({
        room_id: webRoom.room.id,
        share_method: "native_share",
      });
    }
    onShare && onShare();
  };

  const navigator: any = window.navigator;

  const doShareLink = async () => {
    if (!navigator.share) return onError && onError();

    try {
      await navigator.share({
        title: sharingTitle,
        text: sharingText,
        url: sharingUrl,
      });
      onSuccess();
    } catch (error) {
      if (error instanceof Error && error.name === "AbortError") return;
      onError && onError();
    }
  };

  return navigator.share ? (
    <RoomButton
      onClick={doShareLink}
      noHover
      disabled={disabled}
      css={css`
        display: flex;
        align-items: center;
      `}
      {...rest}
    >
      <Share
        color={disabled ? "gray" : undefined}
        css={css`
          width: 1.5rem;
          height: 1.5rem;
          margin-bottom: 0.2rem;
        `}
      />
      <span>{buttonText}</span>
    </RoomButton>
  ) : null;
};

export default ShareButton;
