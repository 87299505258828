import { useContext, useEffect, useState } from "react";
import * as twilio from "twilio-video";

import TwilioContext from "../contexts/Twilio";
import WebRoomContext from "../contexts/WebRoom";
import * as twilioUtils from "../twilio/utils";
import * as types from "../utils/types";

/**
 * Get the remote participants of the room
 */
const useParticipants = () => {
  const webRoom = useContext(WebRoomContext);
  const setWebRoomParticipants = webRoom?.setParticipants;
  const { room } = useContext(TwilioContext);
  const [participants, setParticipants] = useState<twilio.RemoteParticipant[]>(
    []
  );

  useEffect(() => {
    // TODO: this is running twice since it is invoked in both `Room.tsx` and `useAllPublications.tsx`
    // this makes double callbacks to be registered (i.e. `participantConnected`), and these functions to be run twice
    // need to solve this somehow...
    // Temp solving too many callback to be registered with this check:
    if (!room || !setWebRoomParticipants) return;

    const addParticipantToState = async (participant: types.Participant) => {
      if (twilioUtils.getTwilioIdentityVersion(participant.identity) === "v2") {
        const client = twilioUtils.getV2ClientFromIdentity(
          participant.identity
        );
        setWebRoomParticipants((prevParticipants) => {
          return [...prevParticipants].map((prevParticipant) => {
            return client.clientId === prevParticipant.participant?.clientId
              ? {
                  ...prevParticipant,
                  twilioParticipant: participant,
                  twilioParticipantVersion: "v2",
                }
              : prevParticipant;
          });
        });
      }
    };

    const removeParticipantFromState = async (
      participant: twilio.RemoteParticipant
    ) => {
      // a twilio participant has left the twilio room
      if (twilioUtils.getTwilioIdentityVersion(participant.identity) === "v2") {
        setWebRoomParticipants((prevParticipants) => {
          return [...prevParticipants].map((prevParticipant) => {
            return participant.identity ===
              prevParticipant.twilioParticipant?.identity
              ? {
                  ...prevParticipant,
                  twilioParticipant: undefined,
                  twilioParticipantVersion: undefined,
                }
              : prevParticipant;
          });
        });
      }
    };

    const participantConnected = (participant: twilio.RemoteParticipant) => {
      addParticipantToState(participant);
      setParticipants((prevParticipants) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant: twilio.RemoteParticipant) => {
      removeParticipantFromState(participant);
      setParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    };

    const cleanParticipants = () => {
      setParticipants([]);
    };

    // Adding existing participants
    room?.participants.forEach(participantConnected);
    if (room) addParticipantToState(room.localParticipant);

    room?.on("participantConnected", participantConnected);
    room?.on("participantDisconnected", participantDisconnected);
    room?.once("disconnected", () => {
      room?.participants.forEach(participantDisconnected);
      cleanParticipants();
    });
    return () => {
      room?.off("participantConnected", participantConnected);
      room?.off("participantDisconnected", participantDisconnected);
      cleanParticipants();
    };
  }, [room, setWebRoomParticipants]);

  return participants;
};

export default useParticipants;
