import React, { createContext, useContext, useEffect, useState } from "react";
import { useBeforeunload } from "react-beforeunload";
import * as twilio from "twilio-video";
import * as sentry from "@sentry/react";

import WebRoomContext from "./WebRoom";
import * as analytics from "../firebase/analytics";
import * as functions from "../firebase/functions";
import useTwilioCredentials from "../hooks/useTwilioCredentials";
import * as twilioErrors from "../twilio/errors";
import * as twilioRoom from "../twilio/room";
import * as intercom from "../utils/intercom";

type TwilioObjective = "connected" | "disconnected";
const TwilioObjectiveInitValue: TwilioObjective = "connected";

const TwilioContext = createContext<{
  room: twilio.Room | null;
  roomError: twilio.TwilioError | null;
  reconnect: () => void;
  setIsViewingSessionTrue: () => void;
  objective: TwilioObjective;
}>({
  room: null,
  roomError: null,
  reconnect: () => {},
  setIsViewingSessionTrue: () => {},
  objective: TwilioObjectiveInitValue,
});

let startTime: number;
let isViewingSession: boolean;
let lastConnectionError: twilio.TwilioError | undefined;

/**
 * Report analytics events and disconnect from the room
 */
const disconnect = (room: twilio.Room | null, webRoomId?: string) => {
  if (room?.state === "connected") {
    const endTime = performance.now();
    const durationSec = Math.round((endTime - startTime) / 1000);
    if (webRoomId) {
      analytics.logSessionLeaveEvent({
        room_id: webRoomId,
        duration_sec: durationSec,
      });
    }

    if (isViewingSession) {
      intercom.trackEvent(analytics.EVENT_VIEWING_SESSION_END, {
        duration_sec: durationSec,
      });
      if (webRoomId) {
        analytics.logViewingSessionEndEvent({
          duration_sec: durationSec,
          room_id: webRoomId,
        });
      }
    }

    twilioRoom.disconnect(room);
  }
};

export const TwilioContextHolder = (props: { children: React.ReactNode }) => {
  const webRoom = useContext(WebRoomContext);
  const twilioCredentials = useTwilioCredentials();
  const [room, setRoom] = useState<twilio.Room | null>(null);
  const [roomError, setRoomError] = useState<twilio.TwilioError | null>(null);
  const [counter, setCounter] = useState(0);
  const reconnect = () => {
    setObjective("connected");
    setCounter((prevCount) => prevCount + 1);
  };
  const [objective, setObjective] = useState<TwilioObjective>(
    TwilioObjectiveInitValue
  );

  /**
   * Connect to a room
   */
  useEffect(() => {
    if (!twilioCredentials || !webRoom?.room.id) return;
    if (room?.state === "connected" || room?.state === "reconnecting") return;
    let isSubscribed = true;

    setRoomError(null);

    twilioRoom
      .connect(twilioCredentials)
      .then((newTwilioRoom) => {
        if (!isSubscribed) return;

        analytics.logSessionJoinEvent({ room_id: webRoom.room.id });
        startTime = performance.now();
        setRoom(newTwilioRoom);

        newTwilioRoom.once(
          "disconnected",
          (twilioRoom: twilio.Room, error: twilio.TwilioError) => {
            if (error) {
              console.error(
                "You were disconnected from the Room:",
                error.code,
                error.message
              );
              sentry.captureException(error);

              if (
                error.code === twilioErrors.Disconnection.MediaConnection &&
                error.code !== lastConnectionError?.code
              ) {
                lastConnectionError = error;
                setCounter((prevCount) => prevCount + 1);
              } else {
                setObjective("disconnected");
                setRoomError(error);
              }
            } else {
              setObjective("disconnected");
            }
          }
        );
      })
      .catch((error: twilio.TwilioError) => {
        if (error.code === twilioErrors.Connection.UnableToCreateRoom) {
          console.log("Room not active. Recreating.");
          functions.recreateRoom({ roomId: webRoom.room.id }).then(() => {
            console.log("Room recreated. Reconnecting.");
            reconnect();
          });
          return;
        }

        console.error("Failed to join Room:", error.code, error.message);
        if (error.code === twilioErrors.Connection.RoomNotFound) {
          // TODO: figure out how to reproduce this and fix with new recreateTwilioRoom function
          console.log("Room not found error, do recreateTwilioRoom now?");
        }
        sentry.captureException(error);
        setObjective("disconnected");
        setRoomError(error);
      });
    return () => {
      isSubscribed = false;
    };
  }, [room, twilioCredentials, counter, webRoom?.room.id]);

  // Disconnect on unmount
  useEffect(() => {
    return () => {
      disconnect(room, webRoom?.room.id);
    };
  }, [room, webRoom?.room.id]);

  // Disconnect before unload
  useBeforeunload(() => disconnect(room, webRoom?.room.id));

  // Keeping track of a viewing session
  useEffect(() => {
    isViewingSession = false;
  }, []);

  const setIsViewingSessionTrue = () => {
    if (!isViewingSession) {
      isViewingSession = true;
      if (webRoom?.room.id) {
        analytics.logViewingSessionBeginEvent({ room_id: webRoom.room.id });
      }
    }
  };

  return (
    <TwilioContext.Provider
      value={{ room, roomError, reconnect, setIsViewingSessionTrue, objective }}
    >
      {props.children}
    </TwilioContext.Provider>
  );
};

export default TwilioContext;
