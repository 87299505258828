import firebase from "firebase/compat/app";

const analytics = firebase.analytics();

/**
 * Should be sent when user sucessfully signs in
 */
const EVENT_SIGNED_IN = "signed_in_with_web";

/**
 * Dimension to be sent with `EVENT_SIGNED_IN`
 */
const EVENT_DIMENSION_SIGNED_IN_METHOD = "sign_in_method";

/**
 * Should be sent when user share a room
 */
const EVENT_SHARE_ROOM = "share_room";

/**
 * Should be sent when user joins a session
 */
const EVENT_SESSION_JOIN = "session_join_with_web";

/**
 * Should be sent when user leaves a session
 */
const EVENT_SESSION_LEAVE = "session_leave_with_web";

/**
 * Should be sent when any user starts a stream
 */
const EVENT_STREAM_BEGIN = "stream_begin_with_web";

/**
 * Should be sent when any user stops streaming
 */
const EVENT_STREAM_END = "stream_end_with_web";

/**
 * Should be sent when another user starts streaming
 */
const EVENT_VIEWING_BEGIN = "viewing_begin";

/**
 * Should be sent when another user stops streaming
 */
const EVENT_VIEWING_END = "viewing_end";

/**
 * Should be sent the first time `EVENT_VIEWING_BEGIN` is sent during a session
 */
const EVENT_VIEWING_SESSION_BEGIN = "viewing_session_begin";

/**
 * Should be sent when session ends and `EVENT_VIEWING_SESSION_BEGIN` has been sent during the session
 */
export const EVENT_VIEWING_SESSION_END = "viewing_session_end";

export const setCurrentScreen = (screen: string) => {
  analytics.setCurrentScreen(screen);
};

export const logScreenViewEvent = () => {
  analytics.logEvent("screen_view");
};

export const setUserId = (userId: string) => {
  analytics.setUserId(userId);
};

export const logSignedInEvent = (signInMethod?: string) => {
  analytics.logEvent(EVENT_SIGNED_IN, {
    [EVENT_DIMENSION_SIGNED_IN_METHOD]: signInMethod,
  });
};

type ShareMethod = "copy_link" | "native_share";

interface ShareRoomEventParams {
  room_id: string;
  share_method: ShareMethod;
}

export const logShareRoomEvent = (
  shareRoomEventParams: ShareRoomEventParams
) => {
  analytics.logEvent(EVENT_SHARE_ROOM, shareRoomEventParams);
};

interface RoomBeginEventParams {
  /**
   * Web Room ID
   */
  room_id: string;
}

interface RoomEndEventParams extends RoomBeginEventParams {
  /**
   * Duration in seconds since the corresponding begin event
   */
  duration_sec: number;
}

export const logSessionJoinEvent = (eventParams: RoomBeginEventParams) => {
  analytics.logEvent(EVENT_SESSION_JOIN, eventParams);
};

export const logSessionLeaveEvent = (eventParams: RoomEndEventParams) => {
  analytics.logEvent(EVENT_SESSION_LEAVE, eventParams);
};

export const logStreamBeginEvent = (eventParams: RoomBeginEventParams) => {
  analytics.logEvent(EVENT_STREAM_BEGIN, eventParams);
};

export const logStreamEndEvent = (eventParams: RoomEndEventParams) => {
  analytics.logEvent(EVENT_STREAM_END, eventParams);
};

export const logViewingBeginEvent = (eventParams: RoomBeginEventParams) => {
  analytics.logEvent(EVENT_VIEWING_BEGIN, eventParams);
};

export const logViewingEndEvent = (eventParams: RoomEndEventParams) => {
  analytics.logEvent(EVENT_VIEWING_END, eventParams);
};

export const logViewingSessionBeginEvent = (
  eventParams: RoomBeginEventParams
) => {
  analytics.logEvent(EVENT_VIEWING_SESSION_BEGIN, eventParams);
};

export const logViewingSessionEndEvent = (eventParams: RoomEndEventParams) => {
  analytics.logEvent(EVENT_VIEWING_SESSION_END, eventParams);
};
