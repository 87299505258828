/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";

import { RoomButton } from "./roomAtoms";
import Present from "../../images/icons/Present";
import * as colors from "../../utils/colors";

const PresentButton = ({
  active,
  color = colors.GRAPIC_BLACK,
  disabled,
  buttonRef,
  onClick,
  ...rest
}: {
  active: boolean;
  color?: string;
  disabled: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  onClick: () => void;
  className?: string;
}) => {
  return (
    <RoomButton
      ref={buttonRef}
      onClick={onClick}
      disabled={disabled}
      active={active}
      noHover
      {...rest}
    >
      <Present
        color={disabled ? "gray" : color}
        css={css`
          width: 1.5rem;
          height: 1.5rem;
          margin-bottom: 0.2rem;
        `}
      />
      <span>Present</span>
    </RoomButton>
  );
};

export default PresentButton;
