/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { RoughNotation } from "react-rough-notation";

import { FlexCol } from "./atoms";
import * as colors from "../utils/colors";

const Loading = ({
  text = "Ironing the papers",
  ...rest
}: {
  className?: string;
  text?: string;
}) => (
  <FlexCol
    css={css`
      justify-content: center;
      height: 100%;
      text-align: center;
      z-index: 2;
    `}
    {...rest}
  >
    <p
      css={css`
        padding: 1rem;
        font-size: 1.5rem;
        z-index: inherit;
      `}
    >
      <RoughNotation
        type="underline"
        show
        animationDelay={300}
        animationDuration={4000}
        iterations={10}
        padding={10}
        color={colors.GRAPIC_TURQUOISE}
      >
        {text}
      </RoughNotation>
    </p>
  </FlexCol>
);

export default Loading;
