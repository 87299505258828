/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import * as colors from "../utils/colors";
import * as styleUtils from "../utils/styles";

export const Flex = styled.div`
  display: flex;
`;

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.09);
  border-radius: 1.8rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: ${colors.LIGHT_FOREGROUND};
  ${styleUtils.darkMode} {
    background: ${colors.DARK_FOREGROUND};
  }
`;

type ButtonProps = {
  primary?: boolean;
  yellow?: boolean;
  black?: boolean;
  inverted?: boolean;
  noHover?: boolean;
  noShadow?: boolean;
};

const focus = css`
  // Remove outline for non-keyboard :focus
  &:focus:not(.focus-visible) {
    outline: none;
  }

  // Add outline for keyboard :focus
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 1px !important;
  }
`;

export const Button = styled.button<ButtonProps>`
  border-radius: 0.8rem;
  border: none;
  padding: 0.8rem 1.5rem;
  font: inherit;
  appearance: none;
  height: 3.2rem;
  box-shadow: ${({ noShadow }) =>
    !noShadow && `1px 4px 10px rgba(0, 0, 0, 0.07);`};
  font-family: "Poppins", serif;
  white-space: nowrap;

  background: ${({ primary, disabled }) =>
    disabled ? `gray` : primary ? colors.GRAPIC_YELLOW : colors.GRAPIC_WHITE};
  font-weight: ${({ primary }) => primary && `600`};
  color: ${colors.GRAPIC_BLACK};
  cursor: ${({ disabled }) => !disabled && `pointer`};

  ${({ yellow, inverted }) =>
    yellow &&
    `
  background: ${inverted ? colors.GRAPIC_WHITE : colors.GRAPIC_YELLOW};
  border: ${inverted ? `2px solid ${colors.GRAPIC_YELLOW}` : `none`};
  color: ${inverted ? colors.GRAPIC_YELLOW : colors.GRAPIC_BLACK};
  `}

  ${({ black, inverted }) =>
    black &&
    `
  background: ${inverted ? `unset` : colors.GRAPIC_BLACK};
  border: ${inverted ? `2px solid ${colors.GRAPIC_BLACK}` : `none`};
  color: ${inverted ? colors.GRAPIC_BLACK : colors.GRAPIC_WHITE};

  ${styleUtils.darkMode} {
    background: ${inverted ? `unset` : colors.GRAPIC_WHITE};
    border: ${inverted ? `2px solid ${colors.GRAPIC_WHITE}` : `none`};
    color: ${inverted ? colors.GRAPIC_WHITE : colors.GRAPIC_BLACK};
  }
  `}

  &:hover {
    transition: ${({ disabled, noHover }) =>
      !disabled && !noHover && `all 0.2s ease`};
    transform: ${({ disabled, noHover }) =>
      !disabled && !noHover && `scale(1.01)`};
  }

  ${focus};
`;

export const TextButton = styled.button`
  border: none;
  background: none;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  padding: 0;
  cursor: pointer;

  color: ${colors.GRAPIC_BLACK};
  ${styleUtils.darkMode} {
    color: ${colors.GRAPIC_WHITE};
  }

  ${focus};
`;

export const Input = styled.input`
  box-sizing: border-box;
  padding: 1rem;
  height: 3rem;
  border: 1px solid grey;
  border-radius: 0.7rem;
  font-size: initial;
  font-family: "Open Sans", sans;
  font-weight: 500;
  box-shadow: inset 1px 4px 10px rgba(0, 0, 0, 0.03);

  &:read-only {
    filter: brightness(0.95);
  }
`;

export const Label = styled.label`
  font-size: 0.9rem;
  font-weight: 300;
  margin-bottom: 0.1rem;
`;

export const LabelAndInput = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;
  margin-bottom: 1rem;

  &::placeholder {
    color: #bbbbbb;
  }
`;

export const VideoOverlaySpan = styled.span`
  padding: 0.2rem;
  background: ${colors.LIGHT_BACKGROUND};

  ${styleUtils.darkMode} {
    background: ${colors.DARK_BACKGROUND};
  }
`;

export const HighlightText = styled.span`
  font-weight: bold;
`;

export const Highlighter = styled.span`
  background: linear-gradient(
    90deg,
    ${colors.GRAPIC_YELLOW} 0%,
    ${colors.GRAPIC_WHITE} 80%
  );
`;

export const Triangle = styled.div<{ color?: string }>`
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-bottom: 0.5rem solid;

  border-bottom-color: ${({ color }) => color || colors.LIGHT_FOREGROUND};
  ${styleUtils.darkMode} {
    border-bottom-color: ${({ color }) => color || colors.DARK_FOREGROUND};
  }
`;

export const ExternalLink = ({
  to,
  children,
  ...rest
}: {
  to: string;
  className?: string;
  children: React.ReactNode;
}) => (
  <a
    href={to}
    target="_blank"
    rel="noopener noreferrer"
    css={css`
      color: unset;
    `}
    {...rest}
  >
    {children}
  </a>
);

export const Emoji = (props: { symbol: string; label?: string }) => (
  <span
    role="img"
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);
