/**
 * Breakpoints for the screen width media queries.
 */
export const breakpoints = [576, 768, 992, 1200];

/**
 * Queries that applies on screen size steps as defines by `breakpoints`.
 */
export const mq = breakpoints.map((bp) => `@media (min-width: ${bp}px)`);

/**
 * Query that applies on screens with the given max height in pixels.
 */
export const maxScreenHeight = (pixels: number) =>
  `@media screen and (max-height: ${pixels}px)`;

/**
 * Quert that applies when user prefers dark mode.
 */
export const darkMode = `@media (prefers-color-scheme: dark)`;

/**
 * Query that applies to the first child if the element has exactly the given amout of childs.
 * https://alistapart.com/article/quantity-queries-for-css/#section5
 */
export const firstChildOf = (childs: number, elementType = "div") => `
  > ${elementType}:nth-last-of-type(${childs}):first-of-type`;

/**
 * Query that applies if the element has exactly the given amout of childs.
 * https://alistapart.com/article/quantity-queries-for-css/#section5
 */
export const hasChilds = (childs: number, elementType = "div") => `
  > ${elementType}:nth-last-child(${childs}):first-of-type,
  > ${elementType}:nth-last-child(${childs}):first-of-type ~ ${elementType}`;

/**
 * Query that applies if the element has at least the given amout of childs.
 * https://alistapart.com/article/quantity-queries-for-css/#section6
 */
export const hasAtLeastChilds = (childs: number, elementType = "div") => `
  > ${elementType}:nth-last-of-type(n + ${childs}),
  > ${elementType}:nth-last-of-type(n + ${childs}) ~ ${elementType}`;
