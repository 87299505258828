/** @jsxImportSource @emotion/react */

import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import firebase from "firebase/compat/app";

import {
  LabelAndInput,
  Input,
  Label,
  Button,
  TextButton,
  FlexCol,
} from "./atoms";
import Header from "./Header";
import StateContext from "../contexts/State";
import * as firestore from "../firebase/firestore";
import * as firebaseUtils from "../firebase/utils";
import * as intercom from "../utils/intercom";

const signOutUser = () => {
  firebase.auth().signOut();
  intercom.signOutAndReboot();
};

const Settings = () => {
  const history = useHistory();
  const state = useContext(StateContext);

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [displayName, setDisplayName] = useState(state.user?.displayName || "");
  const [optOutMarketingEmails, setOptOutMarketingEmails] = useState(
    Boolean(state.user?.notifications?.email?.optOut)
  );

  const save = async () => {
    if (!state.authUser) return;

    setSuccess(false);
    setLoading(true);
    await firestore.updateUser(state.authUser.uid, {
      displayName: displayName
        ? displayName
        : firebase.firestore.FieldValue.delete(),
      "notifications.email.optOut": optOutMarketingEmails,
    });
    await firebaseUtils.getDatabaseUser(state.authUser, state.setUser);
    setSuccess(true);
    setLoading(false);
  };

  const hasUnsavedChanges =
    (state.user?.displayName
      ? state.user.displayName !== displayName
      : displayName !== "") ||
    Boolean(state.user?.notifications?.email?.optOut) !== optOutMarketingEmails;

  return (
    <div
      css={css`
        max-width: 90rem;
        margin: auto;
      `}
    >
      <Header />
      <div
        css={css`
          padding: 0 1rem 1rem;
          max-width: 25rem;
          margin: auto;
        `}
      >
        <h2
          css={css`
            text-align: center;
          `}
        >
          Account
        </h2>
        {state.authUser?.isAnonymous && (
          <FlexCol>
            <p
              css={css`
                text-align: center;
              `}
            >
              You are a guest user.
              <br />
              Sign in to access all features.
            </p>
            <Button
              onClick={() => state.setSignInModal("upgradeFromAnonymous")}
            >
              Sign in
            </Button>
          </FlexCol>
        )}
        {state.user?.photoURL && (
          <LabelAndInput>
            <img
              src={state.user.photoURL}
              loading="lazy"
              width="160"
              alt="Your profile"
              css={css`
                margin: auto;
                border-radius: 10rem;
              `}
            />
          </LabelAndInput>
        )}

        <LabelAndInput>
          <Label>Email</Label>
          <Input
            value={state.user?.email}
            readOnly
            disabled={state.authUser?.isAnonymous}
          />
        </LabelAndInput>

        <LabelAndInput>
          <Label>Display name</Label>
          <Input
            value={displayName}
            onChange={(event) => {
              setDisplayName(event.target.value);
              setSuccess(false);
            }}
            disabled={state.authUser?.isAnonymous}
            maxLength={42}
          />
        </LabelAndInput>

        <h3
          css={css`
            margin-top: 2.5rem;
          `}
        >
          Notifications
        </h3>
        <LabelAndInput
          css={css`
            flex-direction: row;
          `}
        >
          <Input
            type="checkbox"
            checked={!optOutMarketingEmails}
            onChange={(event) => {
              setOptOutMarketingEmails(!event.target.checked);
              setSuccess(false);
            }}
            disabled={state.authUser?.isAnonymous}
            css={css`
              min-width: 1rem;
              min-height: 1rem;
              height: unset;
              margin-right: 1rem;
            `}
          />
          <Label>Grapic can send me very occasional emails</Label>
        </LabelAndInput>

        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            margin-top: 2rem;
          `}
        >
          <Button
            primary
            onClick={save}
            disabled={!hasUnsavedChanges || loading}
            css={css`
              width: 100%;
            `}
          >
            {loading ? "Saving..." : success ? "Saved!" : "Save"}
          </Button>
        </div>

        <div
          css={css`
            margin: 2rem auto;
            display: flex;
            justify-content: space-between;
          `}
        >
          <TextButton onClick={() => history.goBack()}>← Back</TextButton>
          <TextButton
            onClick={() => {
              state.setUserName("");
              signOutUser();
            }}
          >
            Sign out
          </TextButton>
        </div>
      </div>
    </div>
  );
};

export default Settings;
