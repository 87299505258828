/** @jsxImportSource @emotion/react */

import { Fragment, useContext, useState } from "react";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import * as sentry from "@sentry/react";
import Modal from "react-modal";

import { Button, ExternalLink, Flex, TextButton } from "../atoms";
import { appStoreUrl } from "../AppWaitlistCTA";
import { Step } from "../Stepper";
import StateContext from "../../contexts/State";
import WebRoomContext from "../../contexts/WebRoom";
import * as functions from "../../firebase/functions";
import * as colors from "../../utils/colors";
import * as styleUtils from "../../utils/styles";

const BUTTON_TEXT = "Send to your phone";
const BUTTON_TEXT_SENDING = "Sending...";
const BUTTON_TEXT_SENT = "Sent";
const BUTTON_TEXT_ERROR = "Something went wrong, try again";

Modal.setAppElement(document.getElementById("root") as HTMLElement);

const SendStepWrap = styled(Flex)`
  align-items: center;
  padding: 0.5rem 0;
`;

const SendStep = styled(Step)`
  margin-right: 0.5rem;
`;

const SendToPhoneButton = (props: { className?: string }) => {
  const state = useContext(StateContext);
  const webRoom = useContext(WebRoomContext);
  const [buttonText, setButtonText] = useState(BUTTON_TEXT);

  const [modal, setModal] = useState(false);

  const onClick = async () => {
    if (enabled) {
      setButtonText(BUTTON_TEXT_SENDING);
      try {
        await functions.sendRoomToOwnPhones({
          data: { room: webRoom?.room.id },
        });
        setButtonText(BUTTON_TEXT_SENT);
      } catch (error) {
        sentry.captureException(error);
        setButtonText(BUTTON_TEXT_ERROR);
      }
    } else {
      setModal(true);
    }
  };

  const enabled =
    state.user?.fcmPushTokens?.ios &&
    state.user?.fcmPushTokens?.ios.filter((token) => token !== "").length > 0;

  const step = enabled
    ? 3
    : state.user?.firstSeenOnMobile
    ? 2
    : state.user
    ? 1
    : 0;

  return (
    <Fragment>
      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        className="modal-content"
        overlayClassName="modal-overlay"
        contentLabel="Sign in modal"
        style={{ overlay: { zIndex: 5 } }}
      >
        <Global
          styles={css`
            .modal-content {
              padding: 3rem;

              ${styleUtils.darkMode} {
                color: ${colors.GRAPIC_BLACK};
              }
            }
          `}
        />
        <h2
          css={css`
            margin-top: 0;
          `}
        >
          Join rooms easier
        </h2>
        <p>
          Follow these steps to send a push notification to your phone with the
          room link.
        </p>
        <SendStepWrap>
          <SendStep done={step < 1}>{step < 1 ? `1` : `✓`}</SendStep>
          {step < 1 ? (
            <TextButton
              onClick={() => state.setSignInModal("upgradeFromAnonymous")}
              css={css`
                text-decoration: underline;
              `}
            >
              Sign in
            </TextButton>
          ) : (
            `Sign in`
          )}
        </SendStepWrap>
        <SendStepWrap>
          <SendStep done={step < 2}>{step < 2 ? `2` : `✓`}</SendStep>
          <span>
            {`Download the `}
            <ExternalLink to={appStoreUrl}>{`Grapic iOS app`}</ExternalLink>
            {` and sign in`}
          </span>
        </SendStepWrap>
        <SendStepWrap>
          <SendStep done={step < 3}>{step < 3 ? `3` : `✓`}</SendStep>
          Allow Grapic to send push notifications
        </SendStepWrap>
        <Flex
          css={css`
            justify-content: center;
          `}
        >
          <Button
            primary
            black
            inverted
            noHover
            onClick={() => setModal(false)}
            css={css`
              margin-top: 2rem;
            `}
          >
            Got it
          </Button>
        </Flex>
      </Modal>
      <Button primary onClick={onClick} {...props}>
        <span>{buttonText}</span>
      </Button>
    </Fragment>
  );
};

export default SendToPhoneButton;
