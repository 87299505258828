import * as colors from "../../utils/colors";

const FullscreenBack = ({
  title,
  color = colors.GRAPIC_WHITE,
  size = 40,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M34.6663 3L26.6663 11V3.33333H23.333V16.6667H36.6663V13.3333H28.9997L36.9997 5.33333L34.6663 3Z"
      fill={color}
    />
    <path
      d="M3.33333 23.3359H16.6667V36.6693H13.3333V29.0026L5.33333 37.0026L3 34.6693L11 26.6693H3.33333V23.3359Z"
      fill={color}
    />
  </svg>
);

export default FullscreenBack;
