import firebase from "firebase/compat/app";
import * as types from "../utils/types";

const functions = firebase.functions();

/**
 * Request data for createRoomV1
 */
export interface CreateRoomData {
  /**
   * A firestore web room ID
   */
  roomId?: string;

  /**
   * The device and pluign the room was created from
   */
  from: types.DeviceAndPluginType;
}

/**
 * Response for createRoomNameV1 and createRoomV1
 */
interface CreateRoomResponse {
  roomId: string;
}

export const createRoom = async (
  data: CreateRoomData
): Promise<CreateRoomResponse> => {
  const result = await functions.httpsCallable("createRoomV1")(data);
  return result.data as CreateRoomResponse;
};

/**
 * Request data for recreateTwilioRoomV1
 */
export interface RecreateTwilioRoomData {
  /**
   * The Firestore web room ID
   */
  roomId: string;
}

export const recreateRoom = async (
  data: RecreateTwilioRoomData
): Promise<void> => {
  await functions.httpsCallable("recreateTwilioRoomV1")(data);
  return;
};

/**
 * Request data for getTwilioCredentialsV1
 */
export interface TwilioCredentialsData {
  clientId: string;

  /**
   * The Firestore web room ID
   */
  roomId: string;
}

/**
 * Response for getTwilioCredentialsV1
 */
export interface TwilioCredentialsResponse {
  /**
   * A Twilio access token (as a JWT), with a video grant for the user and room
   */
  accessToken: string;

  /**
   * The Twilio room name which the access token is valid for
   */
  roomName: string;
}

export const fetchTwilioCredentials = async (
  data: TwilioCredentialsData
): Promise<TwilioCredentialsResponse> => {
  const result = await functions.httpsCallable("getTwilioCredentialsV1")(data);
  return result.data as TwilioCredentialsResponse;
};

interface SendRoomToOwnPhonesRequest {
  notificaiton?: { title?: string; body?: string };
  data?: {
    room?: string;
  };
}

export const sendRoomToOwnPhones = async (data: SendRoomToOwnPhonesRequest) => {
  return functions.httpsCallable("sendSessionToOwnPhones")(data);
};
