/** @jsxImportSource @emotion/react */

import { Fragment, useContext, useEffect, useState } from "react";
import * as Twilio from "twilio-video";
import { css } from "@emotion/react";

import { Avatar } from "./Avatars";
import Participant from "./Participant";
import { Flex, FlexCol } from "../atoms";
import GrapicImg from "../GrapicImg";

import StateContext from "../../contexts/State";
import * as firestore from "../../firebase/firestore";
import * as colors from "../../utils/colors";
import * as styleUtils from "../../utils/styles";
import * as types from "../../utils/types";

interface GrapicPresentationProps {
  grapicId: string;
  participant: types.State.RoomParticipant;
  embed?: boolean;
  localParticipant: Twilio.LocalParticipant;
}

const GrapicPresentation = (props: GrapicPresentationProps) => {
  const [grapic, setGrapic] = useState<types.Firestore.Grapic>();
  const [images, setImages] = useState<types.Firestore.GrapicImage[]>();

  useEffect(() => {
    if (!props.participant.participant?.uid) return;

    const unsubscribe = firestore.onGrapicSnapshot(
      props.participant.participant.uid,
      props.grapicId,
      (grapic) => {
        setGrapic(grapic || undefined);
      }
    );
    return () => {
      unsubscribe && unsubscribe();
    };
  }, [props.participant.participant?.uid, props.grapicId]);

  useEffect(() => {
    setImages(
      grapic?.images &&
        Object.values(grapic.images).sort(
          (a, b) => b.createdAt.toMillis() - a.createdAt.toMillis()
        )
    );
  }, [grapic?.images]);

  return (
    <div
      css={css`
        > div {
          :not(:last-child) {
            margin-bottom: 0.75rem;
          }
        }
      `}
    >
      {images?.map((image) => (
        <GrapicImg
          key={image.id}
          image={image}
          participant={props.participant}
          userId={props.participant.participant?.uid}
          css={css`
            border-radius: 0.3rem;
          `}
        />
      ))}
    </div>
  );
};

interface PresentationProps {
  participant: types.State.RoomParticipant;
  embed?: boolean;
  localParticipant: Twilio.LocalParticipant;
  className?: string;
}

const Presentation = (props: PresentationProps) => {
  const { participant, embed, localParticipant, ...rest } = props;
  const state = useContext(StateContext);
  const isMe = props.participant.participant?.clientId === state.clientId;
  const grapicIds = props.participant.participant?.grapics || [];
  const hasTracks = !!props.participant.twilioParticipant?.tracks.size;
  const hasGrapics = grapicIds.length > 0;

  const displayName =
    props.participant?.participant?.displayName ||
    props.participant.twilioParticipant?.identity ||
    "";
  const displayNameToShow = isMe
    ? `${displayName} (You)`
    : props.participant.participant?.deviceType === "ios"
    ? `${displayName}s iPhone is presenting`
    : displayName;

  return (
    <Fragment>
      {(hasTracks || hasGrapics) && (
        <div
          css={css`
            margin: auto;
          `}
          {...rest}
        >
          <Flex
            css={css`
              position: relative;
              margin: auto;
              border-radius: 1rem;
              border: 0.75rem solid ${colors.LIGHT_FOREGROUND};
              background: ${colors.LIGHT_FOREGROUND};

              ${styleUtils.firstChildOf(3)} {
                margin-right: 0.75rem;
              }

              #video,
              #images {
                max-height: 87vh;
              }
              ${styleUtils.maxScreenHeight(800)} {
                #video,
                #images {
                  max-height: 83vh;
                }
              }
              ${styleUtils.maxScreenHeight(700)} {
                #video,
                #images {
                  max-height: 80vh;
                }
              }
              ${styleUtils.maxScreenHeight(600)} {
                #video,
                #images {
                  max-height: 78vh;
                }
              }
              ${styleUtils.maxScreenHeight(500)} {
                #video,
                #images {
                  max-height: 75vh;
                }
              }
              ${styleUtils.maxScreenHeight(400)} {
                #video,
                #images {
                  max-height: 70vh;
                }
              }
            `}
          >
            {props.participant.twilioParticipant && (
              <Participant
                participant={
                  props.participant
                    .twilioParticipant as Twilio.RemoteParticipant
                }
                embed={props.embed}
                localParticipant={props.localParticipant}
              />
            )}
            {hasGrapics && (
              <FlexCol
                id="images"
                css={css`
                  overflow-y: scroll;
                  max-width: 150px;
                  width: 100%;
                `}
              >
                {grapicIds.map((grapicId) => (
                  <GrapicPresentation
                    key={grapicId}
                    grapicId={grapicId}
                    participant={props.participant}
                    embed={props.embed}
                    localParticipant={props.localParticipant}
                  />
                ))}
              </FlexCol>
            )}
            <div
              css={css`
                position: absolute;
                top: -1rem;
                right: -1rem;
                border: 0.25rem solid ${colors.LIGHT_FOREGROUND};
                border-radius: 5rem;
              `}
            >
              <Avatar
                displayName={displayNameToShow}
                photoURL={props.participant.participant?.photoURL}
                imgTitle={displayNameToShow}
                noBounce
                deviceIndicator={props.participant.participant?.deviceType}
                statusIndicator={
                  props.participant.twilioParticipant && colors.GRAPIC_GREEN
                }
              />
            </div>
          </Flex>
        </div>
      )}
    </Fragment>
  );
};

export default Presentation;
