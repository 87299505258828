import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAsync } from "react-use";
import * as sentry from "@sentry/react";
import firebase from "firebase/compat/app";

import Loading from "./Loading";
import StateContext from "../contexts/State";
import * as functions from "../firebase/functions";
import * as figma from "../plugins/figma";
import * as routes from "../utils/routes";
import * as utils from "../utils/utils";

/**
 * Page that automatically creates a new Grapic and forwards user to it.
 * TODO: check if browser is supported before creating room
 */
const New = () => {
  const history = useHistory();
  const location = useLocation();
  const state = useContext(StateContext);
  const { authUser, didLoadUser } = state;
  const [creatingRoom, setCreatingRoom] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const shouldSignInAutomatically =
    searchParams.get(routes.QUERY_AUTO_SIGN_IN) ===
    routes.QUERY_AUTO_SIGN_IN_ANONYMOUS;

  useAsync(async () => {
    if (!didLoadUser) return;

    if (authUser) {
      setCreatingRoom(true);
      try {
        const createdRoomResult = await functions.createRoom({
          from: utils.getDeviceAndPluginType(),
        });
        setCreatingRoom(false);
        figma.postRoom(createdRoomResult.roomId);
        history.push(
          `${
            location.pathname === routes.NEW_GRAPIC_EMBED_FIGMA
              ? routes.EMBED_FIGMA_ROOM_BASE
              : location.pathname === routes.NEW_GRAPIC_EMBED
              ? routes.EMBED_ROOM_BASE
              : routes.ROOM_BASE
          }/${createdRoomResult.roomId}`
        );
      } catch (error) {
        sentry.captureException(error);
        setCreatingRoom(false);
      }
    } else {
      if (shouldSignInAutomatically) {
        const newAnonymousUser = await firebase.auth().signInAnonymously();
        console.log("Created anonymous uid:", newAnonymousUser.user?.uid);
      } else {
        state.setSignInModal("signIn");
      }
    }
  }, [didLoadUser, authUser]);

  if (!state.didLoadUser) {
    return <Loading text="Loading user" />;
  }

  if (!state.authUser) {
    return <Loading text="Signin in anonymously" />;
  }

  if (creatingRoom) {
    return <Loading text="Cleaning the desk" />;
  }

  return <Loading />;
};

export default New;
