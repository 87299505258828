/** @jsxImportSource @emotion/react */

import { RefObject, useContext, useState } from "react";
import { css } from "@emotion/react";

import { RoomButton } from "./roomAtoms";
import WebRoomContext from "../../contexts/WebRoom";
import * as analytics from "../../firebase/analytics";
import Link from "../../images/icons/Link";

const BUTTON_LABEL = "Room link";
const BUTTON_LABEL_SUCCESS = "Link copied";

interface CopyButtonProps {
  className?: string;
  copyUrl: string;
  disabled?: boolean;
  linkInputRef?: RefObject<HTMLInputElement>;
  onCopy?: () => void;
  onError?: () => void;
}

const CopyButton = ({
  copyUrl,
  disabled,
  linkInputRef,
  onCopy,
  onError,
  ...rest
}: CopyButtonProps) => {
  const webRoom = useContext(WebRoomContext);
  const [label, setLabel] = useState(BUTTON_LABEL);

  const onSuccess = () => {
    setLabel(BUTTON_LABEL_SUCCESS);
    if (webRoom?.room.id) {
      analytics.logShareRoomEvent({
        room_id: webRoom.room.id,
        share_method: "copy_link",
      });
    }
    onCopy && onCopy();
  };

  const doCopyLink = async () => {
    if (!navigator.clipboard) {
      // Internet Explorer 6-11
      const d: any = document;
      const isIE = /*@cc_on!@*/ false || !!d.documentMode;
      if (isIE) {
        return doCopyLinkWithClipboardData();
      } else {
        return doCopyLinkWithExecCommand();
      }
    }

    try {
      await navigator.clipboard.writeText(copyUrl);
      onSuccess();
    } catch (error) {
      doCopyLinkWithExecCommand();
    }
  };

  const doCopyLinkWithExecCommand = () => {
    const inputElement =
      linkInputRef?.current || document.createElement("input");

    if (!inputElement) {
      onError && onError();
      console.log("copy to clipboard with execCommand failed, no element");
      return;
    }

    if (!linkInputRef?.current) {
      inputElement.value = copyUrl;
      inputElement.style.top = "-36px";
      inputElement.style.left = "0";
      inputElement.style.position = "fixed";
      document.body.appendChild(inputElement);
      inputElement.focus();
      inputElement.select();
    }

    const range = document.createRange();
    range.selectNode(inputElement);

    const selection = window.getSelection();
    if (!selection) {
      onError && onError();
      console.log("copy to clipboard with execCommand failed, no selection");
      return;
    }
    selection.removeAllRanges();
    selection.addRange(range);
    const success = document.execCommand("copy");
    selection.removeAllRanges();

    if (!linkInputRef?.current) {
      inputElement.remove();
    }

    if (success) {
      onSuccess();
    } else {
      console.log("copy to clipboard with execCommand failed");
    }
  };

  const doCopyLinkWithClipboardData = () => {
    const success =
      // @ts-ignore
      window.clipboardData && window.clipboardData.setData("Text", copyUrl);
    if (!!success) {
      onSuccess();
    } else {
      // fails if user declines permission
      console.log("copy to clipboard with clipboardData failed");
    }
  };

  return (
    <RoomButton
      onClick={doCopyLink}
      noHover
      disabled={disabled}
      css={css`
        display: flex;
        align-items: center;
      `}
      {...rest}
    >
      <Link
        color={disabled ? "gray" : undefined}
        css={css`
          width: 1.5rem;
          height: 1.5rem;
          margin-bottom: 0.2rem;
        `}
      />
      <span>{label}</span>
    </RoomButton>
  );
};

export default CopyButton;
