import * as twilio from "twilio-video";
import * as types from "../utils/types";

/**
 * Get the Twilio identity string to use for this browser participant
 */
// const getTwilioIdentityFromState = (state: StateContextInterface) => {
//   // Use version 1 syntax
//   // return state.authUser?.uid || state.userName || state.placeholderUserName;
//   // Use version 2 syntax
//   return `v2:web:${state.clientId}:${state.authUser?.uid || ""}`;
// };

/**
 * Get the version number of a Twilio identity string.
 * Version 1 syntax: "<userId>" or "<userId>s Phone"
 * Version 2 syntax: "v2:<deviceType>:<clientId>:<userId>"
 * @param identity The Twilio identity string
 */
export const getTwilioIdentityVersion = (
  identity: string
): types.TwilioIdentityVersion => {
  if (identity.startsWith("v2:") && identity.split(":").length === 4) {
    return "v2";
  } else {
    throw new Error(
      "iOS app below v0.6 has been deprecated, please update to the latest Grapic iOS app version."
    );
    // return "v1";
  }
};

/**
 * Twilio identity string version 2:
 * Get a TwilioClient object from the Twilio identity string
 * @param identity
 */
export const getV2ClientFromIdentity = (
  identity: string
): types.TwilioClient => {
  // TODO: warn if something goes wrong here
  const clientArray = identity.split(":");
  const client: types.TwilioClient = {
    version: clientArray[0] as types.TwilioIdentityVersion,
    deviceType: clientArray[1] as types.DeviceType,
    clientId: clientArray[2] as string,
  };
  if (clientArray[3] && clientArray[3] !== "") {
    client.userId = clientArray[3] as string;
  }
  return client;
};

/**
 * Twilio identity string version 1:
 * The string to append to the Twilio identity string to connect as phone
 */
const V1_PHONE_IDENTITY_APPENDIX = "s iPhone";

/**
 * Twilio identity string version 1:
 * Get the phone participant identity from the browser participant identity
 * @param browserParticipantIdentity The Twilio identity of the user in the browser
 */
// const getV1PhoneParticipantFromBrowser = (
//   browserParticipantIdentity: Twilio.Participant.Identity
// ): Twilio.Participant.Identity =>
//   `${browserParticipantIdentity}${V1_PHONE_IDENTITY_APPENDIX}`;

/**
 * Twilio identity string version 1:
 * Get the browser participant identity from the phone participant identity
 * @param phoneParticipantIdentity The Twilio identity of the user on the phone
 */
const getV1BrowserParticipantFromPhone = (
  phoneParticipantIdentity: twilio.Participant.Identity
): twilio.Participant.Identity =>
  phoneParticipantIdentity.replace(V1_PHONE_IDENTITY_APPENDIX, ``);

/**
 * Get the userId from a Twlilio participant identity
 * @param participant The Twilio participant
 */
// export const getUserIdFromParticipant = (
//   participant: types.Participant
// ): string | undefined => {
//   if (getTwilioIdentityVersion(participant.identity) === "v2") {
//     const client = getV2ClientFromIdentity(participant.identity);
//     return client.userId;
//   } else {
//     return isPhoneParticipant(participant)
//       ? getV1BrowserParticipantFromPhone(participant.identity)
//       : participant.identity;
//   }
// };

/**
 * Check if a participant is a phone
 * @param participant
 */
export const isPhoneParticipant = (participant: types.Participant): boolean => {
  if (getTwilioIdentityVersion(participant.identity) === "v2") {
    const client = getV2ClientFromIdentity(participant.identity);
    return client.deviceType === "ios";
  } else {
    return participant.identity.includes(V1_PHONE_IDENTITY_APPENDIX);
  }
};

/**
 * Check if `phoneParticipant` and `browserParticipant` has the same userId
 * @param phoneParticipant
 * @param browserParticipant
 */
export const isPhoneParticipantOf = (
  phoneParticipant: types.Participant,
  browserParticipant?: types.Participant
): boolean => {
  if (!browserParticipant) return false;

  const phoneParticipantUserId =
    getTwilioIdentityVersion(phoneParticipant.identity) === "v2"
      ? getV2ClientFromIdentity(phoneParticipant.identity).userId
      : getV1BrowserParticipantFromPhone(phoneParticipant.identity);

  const browserParticipantUserId =
    getTwilioIdentityVersion(browserParticipant.identity) === "v2"
      ? getV2ClientFromIdentity(browserParticipant.identity).userId
      : browserParticipant.identity;

  const isPhoneParticipantOf =
    !!phoneParticipantUserId &&
    !!browserParticipantUserId &&
    phoneParticipantUserId === browserParticipantUserId;

  return isPhoneParticipantOf;
};
