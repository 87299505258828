/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import * as twilio from "twilio-video";
import { useIdle } from "react-use";

import CopyButton from "./CopyButton";
import LeaveButton from "./LeaveButton";
import PresentButton from "./PresentButton";
import ShareButton from "./ShareButton";

import { Flex } from "../atoms";
import AppWaitlistCTA from "../AppWaitlistCTA";
import TwitterShare from "../social/TwitterShare";
import * as animations from "../../utils/animations";
import * as colors from "../../utils/colors";
import * as styleUtils from "../../utils/styles";
import * as types from "../../utils/types";
import * as utils from "../../utils/utils";

const animateOut = css`
  animation: ${animations.backOutDown} 1s;
  animation-fill-mode: forwards;
`;

const animateIn = css`
  animation: ${animations.backInUp} 1s;
`;

const RoomFooter = (props: {
  embed?: boolean;
  didConnectPhone: boolean;
  presentModalIsOpen: boolean;
  setPresentModalIsOpen: (presentModalIsOpen: boolean) => void;
  userPhoneParticipant?: types.State.RoomParticipant;
  allPublications: twilio.TrackPublication[];
  roomUrl: string;
}) => {
  const hideBottomBarIdleTimer = useIdle(1000 * 2);
  const hideBottomBar =
    props.allPublications.length > 0 &&
    !(props.presentModalIsOpen && !props.userPhoneParticipant) &&
    hideBottomBarIdleTimer;

  return (
    <Flex
      css={css`
        align-items: baseline;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 6rem;
        overflow-x: scroll;
      `}
    >
      <div
        css={css`
            align-self: flex-end;
            position: absolute;
            display: none;
            ${styleUtils.mq[0]} {
              display: block;
              `}
      >
        {props.didConnectPhone ? (
          <TwitterShare
            css={css`
              margin: 1rem;
            `}
          />
        ) : (
          <AppWaitlistCTA
            shownInRoom
            css={css`
              align-self: flex-end;
            `}
          />
        )}
      </div>
      <Flex
        css={css`
          margin: 0 auto;
          align-self: flex-end;
          width: fit-content;
          background: ${colors.GRAPIC_WHITE};
          border-radius: 1rem 1rem 0 0;

          ${styleUtils.mq[0]} {
            display: none;
          }

          z-index: 8;
          ${hideBottomBar ? animateOut : animateIn}
        `}
      >
        <PresentButton
          onClick={() => props.setPresentModalIsOpen(!props.presentModalIsOpen)}
          disabled={!!props.userPhoneParticipant}
          active={props.presentModalIsOpen}
        />
        {utils.isMobile() && window.navigator.share ? (
          <ShareButton sharingUrl={props.roomUrl} />
        ) : (
          <CopyButton copyUrl={props.roomUrl} />
        )}
        {!props.embed && <LeaveButton />}
      </Flex>
    </Flex>
  );
};

export default RoomFooter;
