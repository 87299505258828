import * as utils from "../utils/utils";

const NotSupported = () => (
  <p>
    {utils.isIOS()
      ? "Oh no, we only support Safari on iOS. Please change to Safari to continue."
      : "Oh no, we don't support your browser. Please use a more recent version of Chrome, Safari or Firefox."}
  </p>
);

export default NotSupported;
