/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState, useContext } from "react";
import * as Twilio from "twilio-video";
import { css } from "@emotion/react";
import { useFullscreen, useToggle } from "react-use";

import { Button, Flex, FlexCol, Triangle } from "../atoms";
import TwilioContext from "../../contexts/Twilio";
import WebRoomContext from "../../contexts/WebRoom";
import * as analytics from "../../firebase/analytics";
import Fullscreen from "../../images/icons/Fullscreen";
import FullscreenBack from "../../images/icons/FullscreenBack";
import PictureInPicture from "../../images/icons/PictureInPicture";
import PictureInPictureBack from "../../images/icons/PictureInPictureBack";
import * as colors from "../../utils/colors";
import * as utils from "../../utils/utils";

const exitPictureInPicture = () => {
  // @ts-ignore
  if (document.pictureInPictureElement) {
    // @ts-ignore
    document.exitPictureInPicture().catch(console.log);
  }
};

const requestPictureInPicture = (videoElement: HTMLVideoElement | null) => {
  // @ts-ignore
  videoElement.requestPictureInPicture().catch(console.log);
};

interface VideoTrackProps {
  videoTrack: Twilio.RemoteVideoTrack | null;
  participant: Twilio.RemoteParticipant;
  isUserPhoneParticipant: boolean;
  embed?: boolean;
}

let startTime: number;
let endTime: number;

const VideoTrack = ({
  videoTrack,
  participant,
  isUserPhoneParticipant,
  embed,
}: VideoTrackProps) => {
  const webRoom = useContext(WebRoomContext);
  const { setIsViewingSessionTrue } = useContext(TwilioContext);

  const [, /*videoWidth*/ setVideoWidth] = useState<number | null>(null);
  const [, /*videoHeight*/ setVideoHeight] = useState<number | null>(null);
  const [, /*videoIsEnabled*/ setVideoIsEnabled] = useState(false);
  const [, /*videoIsStarted*/ setVideoIsStarted] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  const [canPip, setCanPip] = useState(false);
  const [isPip, setIsPip] = useState(false);

  const canFullscreen = !utils.isEmbed();
  const fullscreenRef = useRef(null);
  const [showFullscreen, toggleFullscreen] = useToggle(false);
  const isFullscreen = useFullscreen(fullscreenRef, showFullscreen, {
    onClose: () => toggleFullscreen(false),
    video: videoRef,
  });

  useEffect(() => {
    if (
      !canPip &&
      "pictureInPictureEnabled" in document && // the picture-in-picture web API is available
      // @ts-ignore
      document.pictureInPictureEnabled && // the picture-in-picture web API is enabled
      videoRef?.current &&
      "disablePictureInPicture" in videoRef?.current // video element can use picture-in-picture
    ) {
      setCanPip(true);
    } else if (canPip) {
      videoRef.current?.addEventListener("enterpictureinpicture", () =>
        setIsPip(true)
      );
      videoRef.current?.addEventListener("leavepictureinpicture", () =>
        setIsPip(false)
      );
    }
  }, [canPip]);

  useEffect(() => {
    if (!webRoom?.room.id) return;
    analytics.logStreamBeginEvent({ room_id: webRoom.room.id });

    if (!isUserPhoneParticipant) {
      analytics.logViewingBeginEvent({ room_id: webRoom.room.id });
      setIsViewingSessionTrue();
    }

    startTime = performance.now();
    return () => {
      endTime = performance.now();
      const durationSec = Math.round((endTime - startTime) / 1000);
      analytics.logStreamEndEvent({
        duration_sec: durationSec,
        room_id: webRoom.room.id,
      });

      if (!isUserPhoneParticipant) {
        analytics.logViewingEndEvent({
          duration_sec: durationSec,
          room_id: webRoom.room.id,
        });
      }
    };
  }, [isUserPhoneParticipant, webRoom?.room.id, setIsViewingSessionTrue]);

  // Attach the video track to the ref
  useEffect(() => {
    if (videoTrack) {
      videoTrack.attach(videoRef.current || undefined);
      return () => {
        const elements = videoTrack.detach();
        // Chrome 92 limits WebMediaPlayers on a page, this helps clean up media elements
        elements.forEach((element) => {
          element.remove();
          element.srcObject = null;
        });
      };
    }
  }, [videoTrack]);

  // Listening on video track changes
  useEffect(() => {
    const videoTrackUpdated = (videoTrack: Twilio.RemoteVideoTrack) => {
      setVideoWidth(videoTrack.dimensions.width);
      setVideoHeight(videoTrack.dimensions.height);
      setVideoIsEnabled(videoTrack.isEnabled);
      setVideoIsStarted(videoTrack.isStarted);
    };

    if (videoTrack) {
      videoTrack.on("dimensionsChanged", (track) => {
        videoTrackUpdated(track);
      });
      videoTrack.on("enabled", (track) => {
        videoTrackUpdated(track);
      });
      videoTrack.on("disabled", (track) => {
        videoTrackUpdated(track);
      });
      videoTrack.on("started", (track) => {
        videoTrackUpdated(track);
      });
      videoTrack.on("switchedOff", () => {});
      videoTrack.on("switchedOn", () => {});
    }
  }, [videoTrack]);

  return (
    <FlexCol
      css={css`
        flex-grow: 1;
        justify-content: center;
        align-items: center;
      `}
    >
      <Flex
        ref={fullscreenRef}
        css={css`
          position: relative;

          // resize: both; // to make it resizeable
          // overflow: auto; // to make it resizeable
          // height: auto !important; // to make it proportional
          // max-width: 96vw; // to make it not get bigger than the sceen width
          // min-width: 14rem; // to not make it too small

          ${isFullscreen &&
          `
          place-items: center;
          place-content: center;
          `}
        `}
      >
        {/* moved to Presentation */}
        {/* <div
          css={css`
          position: absolute;
          right: 0;
          // border-radius: 0 1rem;
          // border: 0.5rem solid ${colors.LIGHT_FOREGROUND};
          // background: ${colors.LIGHT_FOREGROUND};
          ${isFullscreen &&
            `
            right: 0;
            top: 0;
            border: 0.5rem solid ${colors.GRAPIC_BLACK};
            background: ${colors.GRAPIC_BLACK};
            `}
            `}
            >
            <VideoAvatar
            participant={participant}
            user={user}
            displayName={displayName}
            videoIsStarted={videoIsStarted}
            videoIsEnabled={videoIsEnabled}
            isFullscreen={isFullscreen}
            css={css`
            position: relative;
            `}
            />
          </div> */}
        <Triangle
          color={colors.GRAPIC_TURQUOISE}
          css={css`
            position: absolute;
            top: -0.5rem;
            right: 50%;
          `}
        />
        <video
          id="video"
          ref={videoRef}
          muted
          playsInline
          autoPlay
          css={css`
            // max-width: 96vw;
            max-width: 100%;

            // max-height: 78vh; // if resize, disable this to make it resizeable to full screen height
            // max-height: 530px; // to fit inside chrome w/ bookmarks bar & mac dock
            // max-height: 600px; // to fit inside chrome w/o mac dock
            // max-height: 680px; // to fit inside chrome w/o bookmkars bar & mac dock

            // --- moved to Presentation.tsx ---
            // max-height: 87vh;
            // @media screen and (max-height: 800px) {
            //   max-height: 83vh;
            // }
            // @media screen and (max-height: 700px) {
            //   max-height: 80vh;
            // }
            // @media screen and (max-height: 600px) {
            //   max-height: 78vh;
            // }
            // @media screen and (max-height: 500px) {
            //   max-height: 75vh;
            // }
            // @media screen and (max-height: 400px) {
            //   max-height: 70vh;
            // }

            // border-radius: 0.8rem;
            // box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.09);

            ${embed && `max-height: 100vh !important;`}

            ${isFullscreen
              ? `
              max-height: 100vh;
              width: inherit; // for full width of screen
              background: ${colors.GRAPIC_BLACK};
              `
              : `
              background: ${colors.GRAPIC_BLACK};

              // border: 0.5rem solid ${colors.LIGHT_FOREGROUND};
              border: 0.5rem solid ${colors.GRAPIC_TURQUOISE};
              border-radius: 0.2rem;

              // width: 100%; // if resize
              // background: #cbc2a9 // if resize
            `}
          `}
        />
        <Flex
          css={css`
            position: absolute;
            bottom: 0.6rem;
            right: 1.5rem;
            z-index: 5;
          `}
        >
          {/* <VideoOverlaySpan
          css={css`
            font-size: 0.6rem;
            font-weight: bold;
            margin: 0 1rem;
          `}
        >
          <code>
            {`
        ${videoIsStarted ? "started" : "not started"}
        - ${videoIsEnabled ? "enabled" : "disabled"}
        ${videoWidth && videoHeight ? `- ${videoWidth}:${videoHeight}` : ``}`}
          </code>
        </VideoOverlaySpan> */}
          {canPip && (
            <Button
              noHover
              noShadow
              css={css`
                background: unset;
                border-radius: unset;
                padding: 0;
                margin-right: 0.5rem;
              `}
              onClick={() => {
                if (isPip) {
                  exitPictureInPicture();
                } else {
                  requestPictureInPicture(videoRef.current);
                }
              }}
            >
              {isPip ? (
                <PictureInPictureBack
                  css={css`
                    width: 1.4rem;
                    height: 1.4rem;
                  `}
                />
              ) : (
                <PictureInPicture
                  css={css`
                    width: 1.4rem;
                    height: 1.4rem;
                  `}
                />
              )}
            </Button>
          )}
          {canFullscreen && (
            <Button
              noHover
              noShadow
              css={css`
                background: unset;
                border-radius: unset;
                padding: 0;
              `}
              onClick={() => {
                if (isPip) {
                  exitPictureInPicture();
                }
                toggleFullscreen();
              }}
            >
              {!isFullscreen ? (
                <Fullscreen
                  css={css`
                    width: 1.3rem;
                    height: 1.3rem;
                  `}
                />
              ) : (
                <FullscreenBack
                  css={css`
                    width: 1.3rem;
                    height: 1.3rem;
                  `}
                />
              )}
            </Button>
          )}
        </Flex>
      </Flex>
      {/* <VideoOverlaySpan css={{ fontSize: "0.7rem" }}>
        {!videoIsStarted ? (
          <Fragment>
            <Cirle
              color={colors.GRAPIC_RED}
              size={8}
              css={{ marginRight: "0.5rem" }}
            />
            Not started
          </Fragment>
        ) : videoIsStarted && !videoIsEnabled ? (
          <Fragment>
            <Cirle
              color={colors.GRAPIC_YELLOW}
              size={8}
              css={{ marginRight: "0.5rem" }}
            />
            Disabled
          </Fragment>
        ) : (
          <Fragment>
            <Cirle
              color={colors.GRAPIC_GREEN}
              size={8}
              css={{ marginRight: "0.5rem" }}
            />
            Streaming
          </Fragment>
        )}
        {` ${displayName ? `${displayName}'s iPhone` : participant.identity}`}
      </VideoOverlaySpan> */}
    </FlexCol>
  );
};

export default VideoTrack;
