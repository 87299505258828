import * as twilio from "twilio-video";
import * as routes from "./routes";
import * as types from "./types";
import * as figma from "../plugins/figma";

export const isBrowserSupported = () =>
  twilio.isSupported || figma.isFigmaDesktopApp();

export const isIOS = () =>
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);

export const isMobile = () =>
  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const isEmbed = () =>
  window.location.pathname.includes(routes.EMBED_ROOM_BASE) ||
  window.location.pathname.includes(routes.EMBED_FIGMA_ROOM_BASE);

/**
 * Get the device and plugin type of this app
 */
export const getDeviceAndPluginType = (): Exclude<
  types.DeviceAndPluginType,
  { device: "ios" }
> =>
  figma.isProbablyFigma()
    ? { device: "web", plugin: "figma" }
    : { device: "web" };
