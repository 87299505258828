import { Fragment, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import firebase from "firebase/compat/app";

import RoomError from "./RoomError";
import TwilioRoom from "./TwilioRoom";
import Loading from "../Loading";

import StateContext from "../../contexts/State";
import { TwilioContextHolder } from "../../contexts/Twilio";
import { WebRoomContextHolder } from "../../contexts/WebRoom";
import * as utils from "../../utils/utils";
import * as routes from "../../utils/routes";

interface RoomProps {
  embed?: boolean;
}

const Room = (props: RoomProps) => {
  const { didLoadUser, authUser, setSignInModal } = useContext(StateContext);

  // TODO: combine this code with the one in New.tsx
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const shouldSignInAutomatically =
    searchParams.get(routes.QUERY_AUTO_SIGN_IN) ===
    routes.QUERY_AUTO_SIGN_IN_ANONYMOUS;

  useEffect(() => {
    if (didLoadUser && !authUser) {
      if (shouldSignInAutomatically) {
        firebase.auth().signInAnonymously();
      } else {
        setSignInModal("signInWithAnonymous");
      }
    }
  }, [didLoadUser, authUser, setSignInModal, shouldSignInAutomatically]);

  return (
    <Fragment>
      {!utils.isBrowserSupported() ? (
        <RoomError reason="browser_not_supported" />
      ) : didLoadUser && authUser ? (
        <WebRoomContextHolder>
          <TwilioContextHolder>
            <TwilioRoom {...props} />
          </TwilioContextHolder>
        </WebRoomContextHolder>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
};

export default Room;
