import * as colors from "../../utils/colors";

const Wave = ({
  title,
  color = colors.GRAPIC_BLACK,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    width="95"
    height="96"
    viewBox="0 0 95 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M83.9541 84.709C96.1122 72.5508 98.0652 57.4629 89.7158 39.8848L78.7295 16.7402C77.9482 15.1289 77.0205 13.8105 75.9951 12.7852C72.333 9.12299 67.0107 9.31838 63.4951 12.834C61.1025 15.2266 60.3701 18.791 61.9326 22.6484L65.2041 30.4609C65.3017 30.6562 65.3017 30.8516 65.1552 30.998C64.9599 31.1445 64.7646 31.0957 64.5693 30.9492L36.8838 3.26369C32.8799 -0.789114 27.8017 -0.789119 23.9443 3.06838C22.5283 4.43548 21.747 6.24219 21.6982 8.09769C17.6943 5.02149 13.251 5.11909 9.83296 8.53709C6.46376 11.9062 6.36616 16.4961 9.24696 20.4023C7.48916 20.4023 5.82906 21.1836 4.55946 22.4531C0.995046 26.0176 1.09271 30.9004 4.90126 34.709L8.75876 38.5664C6.85446 38.5176 4.95016 39.3965 3.48526 40.8613C-0.128004 44.4746 0.0673158 49.6016 3.92476 53.459L33.6123 83.1465C49.7744 99.3086 69.3545 99.3086 83.9541 84.709ZM79.0224 80.1191C66.8154 92.3262 51.4834 91.9355 37.6162 78.0195L9.00286 49.4062C7.48916 47.9414 7.44036 45.9395 8.85636 44.5234C10.2724 43.1074 12.3232 43.1074 13.7881 44.5723L31.4638 62.1992C32.7334 63.4688 34.5888 63.3223 35.7119 62.1992C36.9326 61.0273 37.0791 59.123 35.8095 57.8535L9.44236 31.4375C7.97746 29.9727 7.87986 28.0195 9.29586 26.6035C10.7119 25.1387 12.8115 25.1387 14.2763 26.6035L38.8857 51.2617C40.1552 52.5312 42.0595 52.3359 43.1826 51.2129C44.3545 50.041 44.5498 48.1367 43.2802 46.8672L14.374 17.9609C12.9092 16.4961 12.8115 14.4453 14.2763 13.0293C15.6435 11.6133 17.7431 11.6621 19.208 13.127L48.1142 42.0332C49.2861 43.2539 51.1904 43.2051 52.3623 42.0332C53.583 40.8125 53.6806 38.8594 52.5088 37.6875L27.8506 13.0293C26.3857 11.5645 26.3369 9.56249 27.7529 8.09769C29.2177 6.68159 31.2197 6.73048 32.6845 8.19528L66.6689 42.1797C68.3779 43.9375 70.4287 43.8887 71.8447 42.4727C73.0654 41.252 73.6025 39.6406 72.626 37.1016L66.5224 21.0859C65.6924 18.8887 66.4736 17.1309 68.1338 16.3496C69.9892 15.5195 71.6494 16.3984 72.7236 18.6934L84.0517 42.6191C91.6201 58.6348 88.6416 70.4512 79.0224 80.1191Z"
      fill={color}
    />
  </svg>
);

export default Wave;
