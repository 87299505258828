/** @jsxImportSource @emotion/react */

import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Box, Button } from "../atoms";

import tutorialStepTwoImage from "../../images/illustrations/Tutorial_Step_2.png";
import ideasImage from "../../images/illustrations/Capture_Ideas_transparent.png";
import squigglyPattern from "../../images/illustrations/Squiggly_Pattern_2.png";
import * as styleUtils from "../../utils/styles";

export const InfoMessageBox = styled(Box)`
  width: 98%;
  ${styleUtils.mq[1]} {
    width: 100%;
  }
  max-width: 40rem;
  margin: auto;
  padding: 2rem;

  position: absolute;
  z-index: 5;
  place-self: center;
  min-height: 22rem;
`;

export const InfoMessageText = styled.p`
  font-size: 1.2rem;
  font-weight: lighter;
  margin-top: 0.4rem;
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(${squigglyPattern});
  background-size: 200px;
  filter: opacity(0.1);
  ${styleUtils.darkMode} {
    filter: opacity(0.9);
  }
  z-index: 0;
`;

export const ConnectionError = styled(Box)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10;
  margin-right: 1rem;
  border-radius: 0.5rem;
`;

export const RoomMessage = styled(InfoMessageBox)`
  z-index: 6;
  min-height: unset;
  max-width: 30rem;
  box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.3);
`;

export const RoomButton = styled(Button)<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 0.4rem;
  width: 6.2rem;
  height: 4rem;
  border-radius: 0.8rem;
  font-size: 0.7rem;
  font-weight: 300;
  box-shadow: unset;
  background: unset;

  ${({ active }) =>
    active &&
    `
    font-weight: 600;
    text-decoration: underline;
  `}
`;

export const IdeaImage = (props: React.ImgHTMLAttributes<HTMLImageElement>) => (
  <img
    src={ideasImage}
    alt="A light bulb and a pen"
    loading="lazy"
    width="280"
    height="210"
    css={css`
      align-self: center;
      max-width: 100%;
      object-fit: contain;
      ${styleUtils.darkMode} {
        filter: contrast(2);
      }
    `}
    {...props}
  />
);

export const HoverImage = (
  props: React.ImgHTMLAttributes<HTMLImageElement>
) => (
  <img
    src={tutorialStepTwoImage}
    alt="A phone hovering a pen and paper"
    loading="lazy"
    width="240"
    height="180"
    css={css`
      align-self: center;
      max-width: 100%;
      object-fit: contain;
      ${styleUtils.darkMode} {
        filter: contrast(2);
      }
    `}
    {...props}
  />
);
