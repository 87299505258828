/** @jsxImportSource @emotion/react */

import React, { useContext } from "react";
import * as twilio from "twilio-video";
import { css } from "@emotion/react";

import { Flex, FlexCol } from "../atoms";
import ProfilePicture from "../ProfilePicture";
import StateContext from "../../contexts/State";
import WebRoomContext from "../../contexts/WebRoom";
import useRoomState, { RoomStateType } from "../../hooks/useRoomState";
import useParticipants from "../../hooks/useParticipants";
import Circle from "../../images/icons/Circle";
import phoneImage from "../../images/illustrations/Phone_2_Filled.png";
import * as twilioUtils from "../../twilio/utils";
import * as animations from "../../utils/animations";
import * as colors from "../../utils/colors";
import * as styleUtils from "../../utils/styles";
import * as types from "../../utils/types";

interface PhoneProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  noBounce?: boolean;
}

const Phone = ({ noBounce, ...rest }: PhoneProps) => (
  <img
    src={phoneImage}
    alt="A mobile phone"
    loading="lazy"
    width="29"
    height="32"
    css={css`
      align-self: center;
      max-width: 100%;
      object-fit: contain;
      ${styleUtils.darkMode} {
        filter: contrast(2);
      }
      ${!noBounce &&
      css`
        animation: ${animations.bounceInDown} 1s;
      `}
    `}
    {...rest}
  />
);

export const Avatar = (props: {
  displayName: string;
  imgTitle?: string;
  photoURL?: string;
  noBounce?: boolean;
  deviceIndicator?: string;
  statusIndicator?: string;
}) => (
  <div
    css={css`
      position: relative;
    `}
  >
    {props.deviceIndicator === "ios" && (
      <Phone
        noBounce={props.noBounce}
        css={css`
          position: absolute;
          right: 1.25rem;
          z-index: 2;
        `}
      />
    )}
    <ProfilePicture
      displayName={props.displayName}
      imgTitle={props.imgTitle}
      photoURL={props.photoURL}
      noBounce={props.noBounce}
    />
    {props.statusIndicator && (
      <Circle
        color={props.statusIndicator}
        css={css`
          position: absolute;
          right: -0.2rem;
          bottom: -0.2rem;
          border: 2px solid white;
          border-radius: 10rem;
        `}
      />
    )}
  </div>
);

const ParticipantComponent = (props: {
  participant: types.Firestore.WebRoomParticipant;
  isMe: boolean;
  photoURL?: string;
  localTwilioParticipant?: twilio.LocalParticipant;
  twilioParticipant?: twilio.RemoteParticipant;
}) => {
  const displayName =
    props.participant.displayName || props.twilioParticipant?.identity || "";
  const displayNameToShow = props.isMe
    ? `${displayName} (You)`
    : props.participant.deviceType === "ios" && displayName
    ? `${displayName}s iPhone`
    : props.participant.deviceType === "ios"
    ? `Anonymous iPhone`
    : displayName;

  const roomState = useRoomState();
  const isOnline: boolean =
    !!props.twilioParticipant || (props.isMe && !!props.localTwilioParticipant);

  const onlineStatus: RoomStateType | "unknown" = props.isMe
    ? roomState
    : roomState !== "connected"
    ? "unknown"
    : isOnline
    ? "connected"
    : "disconnected";

  return (
    <FlexCol
      title={`${displayNameToShow}${
        onlineStatus !== "unknown"
          ? ` is ${
              onlineStatus === "connected"
                ? "online"
                : onlineStatus === "reconnecting"
                ? "reconnecting"
                : "offline"
            }`
          : ""
      }`}
      css={css`
        align-items: center;
        padding: 0 0.5rem;
        ${styleUtils.mq[0]} {
          padding: 0 0.75rem;
        }
        ${styleUtils.mq[1]} {
          padding: 0 1rem;
        }
      `}
    >
      <Flex
        css={css`
          margin-bottom: 0.1rem;
        `}
      >
        <Avatar
          displayName={displayName}
          photoURL={props.photoURL}
          deviceIndicator={props.participant.deviceType}
          statusIndicator={
            onlineStatus === "connected"
              ? colors.GRAPIC_GREEN
              : onlineStatus === "reconnecting"
              ? colors.GRAPIC_YELLOW
              : undefined
          }
        />
      </Flex>
      {/* <span
        css={css`
          font-size: 0.6rem;
          text-align: center;
        `}
      >
        {displayNameToShow}
      </span> */}
    </FlexCol>
  );
};

const Avatars = ({
  localParticipant,
}: {
  localParticipant: twilio.LocalParticipant;
}) => {
  const state = useContext(StateContext);
  const webRoom = useContext(WebRoomContext);
  const twilioParticipants = useParticipants();
  const participants = Object.values(webRoom?.participants || [])
    .filter((participant) => !!participant.participant)
    .sort((a, b) =>
      !a.participant && !b.participant
        ? 0
        : !a.participant
        ? 1
        : !b.participant
        ? -1
        : a.participant.clientId.localeCompare(b.participant.clientId)
    );

  return (
    <Flex
      css={css`
        align-items: start;
      `}
    >
      {participants.map(
        ({ participant }) =>
          participant && (
            <ParticipantComponent
              key={participant.clientId}
              participant={participant}
              isMe={participant.clientId === state.clientId}
              photoURL={participant.photoURL}
              localTwilioParticipant={
                // the local participant is always a V2 participant
                twilioUtils.getV2ClientFromIdentity(localParticipant.identity)
                  .clientId === state.clientId
                  ? localParticipant
                  : undefined
              }
              twilioParticipant={twilioParticipants.find(
                (twilioParticipant) =>
                  twilioUtils.getV2ClientFromIdentity(
                    twilioParticipant.identity
                  ).clientId === participant.clientId
              )}
            />
          )
      )}
    </Flex>
  );
};

export default Avatars;
