import firebase from "firebase/compat/app";

import * as types from "../utils/types";

const COLLECTION_USERS = "users";
const COLLECTION_USER_GRAPICS = "grapics";
const COLLECTION_USER_PUSH_TOKENS = "pushTokens";
const DOCUMENT_USER_PUSH_TOKENS_FCM = "fcm";
const COLLECTION_ROOMS = "rooms";

const firestore = firebase.firestore();

export const getUser = async (userId: string) => {
  const doc = await firestore.collection(COLLECTION_USERS).doc(userId).get();
  return doc.exists && (doc.data() as types.Firestore.DatabaseUser);
};

export const updateUser = async (
  userId: string,
  userUpdate: {
    [key in
      | keyof types.Firestore.DatabaseUser
      | "notifications.email.optOut"]?: any;
  }
) => {
  return firestore.collection(COLLECTION_USERS).doc(userId).update(userUpdate);
};

export const createUser = async (
  userId: string,
  user: types.Firestore.DatabaseUser
) => {
  return firestore.collection(COLLECTION_USERS).doc(userId).set(user);
};

export const getUserPushTokens = async (userId: string) => {
  const doc = await firestore
    .collection(COLLECTION_USERS)
    .doc(userId)
    .collection(COLLECTION_USER_PUSH_TOKENS)
    .doc(DOCUMENT_USER_PUSH_TOKENS_FCM)
    .get();
  return doc.exists && (doc.data() as types.Firestore.FcmPushTokens);
};

export const getGrapic = async (userId: string, grapicId: string) => {
  // TODO: filter away deletedAt grapics
  const doc = await firestore
    .collection(COLLECTION_USERS)
    .doc(userId)
    .collection(COLLECTION_USER_GRAPICS)
    .doc(grapicId)
    .get();
  return doc.exists && (doc.data() as types.Firestore.Grapic);
};

export const getAllGrapics = async (userId: string) => {
  // TODO: filter away deletedAt grapics
  const querySnapshot = await firestore
    .collection(COLLECTION_USERS)
    .doc(userId)
    .collection(COLLECTION_USER_GRAPICS)
    .orderBy("createdAt", "desc")
    .get();
  return querySnapshot.docs.map((doc) => doc.data() as types.Firestore.Grapic);
};

export const onGrapicSnapshot = (
  userId: string,
  grapicId: string,
  onSnapshot: (snapshot: types.Firestore.Grapic | false) => void
) => {
  // TODO: filter away deletedAt grapics
  return firestore
    .collection(COLLECTION_USERS)
    .doc(userId)
    .collection(COLLECTION_USER_GRAPICS)
    .doc(grapicId)
    .onSnapshot((snapshot) => {
      const grapic =
        snapshot.exists && (snapshot.data() as types.Firestore.Grapic);
      onSnapshot(grapic);
    });
};

export const updateGrapic = async (
  userId: string,
  grapicId: string,
  grapicUpdate: any
) => {
  return await firestore
    .collection(COLLECTION_USERS)
    .doc(userId)
    .collection(COLLECTION_USER_GRAPICS)
    .doc(grapicId)
    .update(grapicUpdate);
};

export const getRoom = async (roomId: string) => {
  const doc = await firestore.collection(COLLECTION_ROOMS).doc(roomId).get();
  return doc.exists && (doc.data() as types.Firestore.WebRoom);
};

export const onRoomSnapshot = (
  roomId: string,
  onSnapshot: (snapshot: types.Firestore.WebRoom | false) => void
) => {
  return firestore
    .collection(COLLECTION_ROOMS)
    .doc(roomId)
    .onSnapshot((snapshot) => {
      const webRoom =
        snapshot.exists && (snapshot.data() as types.Firestore.WebRoom);
      onSnapshot(webRoom);
    });
};

export const updateRoom = async (
  roomId: string,
  roomUpdate: { [key in keyof types.Firestore.WebRoom]?: any }
) => {
  return firestore.collection(COLLECTION_ROOMS).doc(roomId).update(roomUpdate);
};

// export const onGrapicInRoomSnapshot = (
//   userId: string,
//   webRoomName: string,
//   onSnapshot: (snapshot: types.Firestore.Grapic[]) => void
// ) => {
//   return firestore
//     .collection(COLLECTION_USERS)
//     .doc(userId)
//     .collection(COLLECTION_USER_GRAPICS)
//     .where("twilioStreamInfo.roomName", "==", webRoomName)
//     .onSnapshot((querySnapshot) => {
//       const grapics = querySnapshot.docs.map(
//         (doc) => doc.data() as types.Firestore.Grapic
//       );
//       onSnapshot(grapics);
//     });
// };
