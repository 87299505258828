/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { css } from "@emotion/react";

import CopyButton from "./CopyButton";
import LeaveButton from "./LeaveButton";
import PresentButton from "./PresentButton";
import ShareButton from "./ShareButton";
import { FlexCol, TextButton } from "../atoms";
import Arrow from "../../images/icons/Arrow";
import * as animations from "../../utils/animations";
import * as colors from "../../utils/colors";
import * as styleUtils from "../../utils/styles";
import * as types from "../../utils/types";
import * as utils from "../../utils/utils";

const ANIMATION_IN_MS = 300;

interface RoomRightBarProps {
  embed?: boolean;
  presentModalIsOpen: boolean;
  setPresentModalIsOpen: (presentModalIsOpen: boolean) => void;
  userPhoneParticipant?: types.State.RoomParticipant;
  roomUrl: string;
}

let timer: NodeJS.Timeout;

const RoomRightBar = (props: RoomRightBarProps) => {
  const [isBarOpen, setIsBarOpen] = useState(true);
  const [isBarCompletelyHidden, setIsBarCompletelyHidden] = useState(
    !isBarOpen
  );

  useEffect(() => {
    clearTimeout(timer);
    if (isBarOpen) {
      setIsBarCompletelyHidden(false);
    } else {
      timer = setTimeout(() => setIsBarCompletelyHidden(true), ANIMATION_IN_MS);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isBarOpen]);

  return (
    <div
      css={css`
        position: absolute;
        right: 0;
        height: 100%;
        align-items: center;

        display: none;
        ${styleUtils.mq[0]} {
          display: flex;
        }

        animation: ${isBarOpen
            ? animations.moveRightBackwards
            : animations.moveRight}
          ${ANIMATION_IN_MS}ms linear;
        animation-fill-mode: forwards;
      `}
    >
      <FlexCol
        css={css`
          position: relative;
          padding: 1rem 0;
          align-items: baseline;
          background: ${colors.LIGHT_FOREGROUND};
          border-radius: 1rem 0 0 1rem;

          z-index: 5;
          box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.07);
        `}
      >
        <TextButton
          onClick={() => {
            props.setPresentModalIsOpen(false);
            setIsBarOpen(!isBarOpen);
          }}
          css={css`
            display: flex;
            position: absolute;
            top: 0.25rem;
            left: 0rem;
            animation: ${isBarOpen
                ? animations.rotate180
                : animations.rotate180Backwards}
              ${ANIMATION_IN_MS}ms linear;
            animation-fill-mode: forwards;
            ${styleUtils.darkMode} {
              color: ${colors.GRAPIC_BLACK};
            }
          `}
        >
          <Arrow />
        </TextButton>

        <PresentButton
          onClick={() => props.setPresentModalIsOpen(!props.presentModalIsOpen)}
          disabled={isBarCompletelyHidden || !!props.userPhoneParticipant}
          active={props.presentModalIsOpen}
        />
        {utils.isMobile() && window.navigator.share ? (
          <ShareButton
            sharingUrl={props.roomUrl}
            disabled={isBarCompletelyHidden}
          />
        ) : (
          <CopyButton
            copyUrl={props.roomUrl}
            disabled={isBarCompletelyHidden}
          />
        )}
        {!props.embed && <LeaveButton disabled={isBarCompletelyHidden} />}
      </FlexCol>
    </div>
  );
};

export default RoomRightBar;
