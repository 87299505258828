import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import firebase from "firebase/compat/app";
import * as sentry from "@sentry/react";
import * as uuid from "uuid";

import * as analytics from "../firebase/analytics";
import * as firebaseUtils from "../firebase/utils";
import useWithStorage from "../hooks/useWithStorage";
import * as intercom from "../utils/intercom";
import * as routes from "../utils/routes";
import * as types from "../utils/types";

const generate = require("project-name-generator");

type SignInModalState =
  | "signIn"
  | "signInWithAnonymous"
  | "upgradeFromAnonymous"
  | "closed";

export interface StateContextInterface {
  clientId: string;
  placeholderUserName: types.UserName;
  userName: types.UserName;
  setUserName:
    | React.Dispatch<React.SetStateAction<types.UserName>>
    | (() => void);
  authUser: types.AuthenticationUser;
  setAuthUser:
    | React.Dispatch<React.SetStateAction<types.AuthenticationUser>>
    | (() => void);
  user: types.Firestore.DatabaseUser | null;
  setUser:
    | React.Dispatch<React.SetStateAction<types.Firestore.DatabaseUser | null>>
    | (() => void);
  isNewUser: boolean;
  setIsNewUser: React.Dispatch<React.SetStateAction<boolean>> | (() => void);
  didLoadUser: boolean;
  signInModal: SignInModalState;
  setSignInModal:
    | React.Dispatch<React.SetStateAction<SignInModalState>>
    | (() => void);
}

const StateContext = createContext<StateContextInterface>({
  clientId: "",
  placeholderUserName: "",
  userName: "",
  setUserName: () => {},
  authUser: null,
  setAuthUser: () => {},
  user: null,
  setUser: () => {},
  isNewUser: false,
  setIsNewUser: () => {},
  didLoadUser: false,
  signInModal: "closed",
  setSignInModal: () => {},
});

const placeholderUserName: types.UserName = `Anonymous ${
  generate({ words: 1 }).spaced
}`;

export const StateContextHolder = (props: { children: React.ReactNode }) => {
  const queryParams = new URLSearchParams(useLocation().search);

  // Client ID
  const [clientId, setClientId] = useWithStorage("clientId", "session");
  useEffect(() => {
    if (!clientId) setClientId(uuid.v4());
  }, [clientId, setClientId]);

  // User name
  const [userName, setUserName] = useWithStorage(routes.QUERY_USER, "local");
  // TODO: remove this feature
  const queryUserName = queryParams.get(routes.QUERY_USER);
  useEffect(() => {
    if (queryUserName && userName !== queryUserName) {
      setUserName(queryUserName);
    }
  }, [userName, setUserName, queryUserName]);

  // Firebase user
  const [authUser, setAuthUser] = useState<types.AuthenticationUser>(null);
  const [didLoadUser, setDidLoadUser] = useState(false);
  const [user, setUser] = useState<types.Firestore.DatabaseUser | null>(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (newAuthUser) => {
      if (newAuthUser) {
        analytics.setUserId(newAuthUser.uid);
        sentry.setUser({ id: newAuthUser.uid });
        if (!newAuthUser.isAnonymous) {
          await firebaseUtils.getDatabaseUser(newAuthUser, setUser);
        }
      } else {
        sentry.setUser(null);
        setUser(null);
      }
      setAuthUser((prevAuthUser) => {
        const shouldUpdateIntercom: boolean =
          (!prevAuthUser || prevAuthUser.isAnonymous) &&
          !!newAuthUser &&
          !newAuthUser.isAnonymous;
        if (shouldUpdateIntercom) {
          // TODO: maybe we can always update intercom and skip this check
          intercom.updateUser(newAuthUser);
        }
        return newAuthUser;
      });
      setDidLoadUser(true);
    });
  }, []);

  // Sign in modal
  const [signInModal, setSignInModal] = useState<SignInModalState>(
    firebase.auth().isSignInWithEmailLink(window.location.href)
      ? "signIn"
      : "closed"
  );

  const [isNewUser, setIsNewUser] = useState<boolean>(false);

  return (
    <StateContext.Provider
      value={{
        clientId,
        placeholderUserName,
        userName,
        setUserName,
        authUser,
        setAuthUser,
        user,
        setUser,
        isNewUser,
        setIsNewUser,
        signInModal,
        setSignInModal,
        didLoadUser,
      }}
    >
      {props.children}
    </StateContext.Provider>
  );
};

export default StateContext;
