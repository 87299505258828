import * as colors from "../../utils/colors";

const PictureInPictureBack = ({
  title = `Back to tab`,
  color = colors.GRAPIC_YELLOW,
  size = 48,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M38 38H10V10h14V6H10c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4V24h-4v14zM28 6v4h7.17L15.51 29.66l2.83 2.83L38 12.83V20h4V6H28z"
      fill={color}
    />
  </svg>
);

export default PictureInPictureBack;
