/**
 * Twilio room connection error codes from:
 * https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#connection-errors
 *
 */
export enum Connection {
  /**
   * The client could not establish a connection to Twilio's signaling server
   */
  SignalingConnection = 53000,

  /**
   * Twilio's signaling server is too busy to accept new clients
   */
  SignalingServerBusy = 53006,

  /**
   * Raised whenever the Server is unable to create a Room
   */
  UnableToCreateRoom = 53103,

  /**
   * The Room cannot allow in any more Participants to join
   */
  RoomMaxParticipantsExceeded = 53105,

  /**
   * The client attempted to connect to a Room that does not exist
   */
  RoomNotFound = 53106,

  /**
   * The client failed to establish a media connection with the Room
   */
  MediaConnection = 53405,
}

/**
 * Twilio room disconnection error codes from:
 * https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices#disconnection-errors
 */
export enum Disconnection {
  /**
   * The client failed to reconnect to Twilio's signaling server after a network disruption or handoff
   */
  SignalingConnectionDisconnected = 53001,

  /**
   * The liveliness checks for the connection to Twilio's signaling server failed, or the current session expired
   */
  SignalingConnectionTimeout = 53002,

  /**
   * Another client joined the Room with the same identity
   */
  ParticipantDuplicateIdentity = 53205,

  /**
   * The client failed to re-establish its media connection with the Room after a network disruption or handoff
   */
  MediaConnection = 53405,
}
