/** @jsxImportSource @emotion/react */

import { Fragment, useContext, useEffect, useState } from "react";
import { useAsync } from "react-use";
import { css } from "@emotion/react";
import {
  LazyLoadImage,
  LazyLoadImageProps,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

import { Flex } from "./atoms";
import StateContext from "../contexts/State";
import * as storage from "../firebase/storage";
import * as figma from "../plugins/figma";
import * as types from "../utils/types";

// interface GrapicImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
interface GrapicImgProps extends LazyLoadImageProps {
  image: types.Firestore.GrapicImage;
  userId?: string;
  participant?: types.State.RoomParticipant;
  onSnapshotClick?: (image: types.Firestore.GrapicImage) => void;
  type?: "fixed" | "fluid";
  width?: number;
}

const center = css`
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
`;

const GrapicImg = ({
  image,
  participant,
  userId,
  onSnapshotClick,
  type = "fluid",
  width = 150,
  ...imageProps
}: GrapicImgProps) => {
  const asyncUrlState = useAsync(async () => {
    if (image.remoteStatus !== "uploaded") return;
    if (!image.userRelativePath) return;
    if (!userId) return;

    const url = await storage.getUserImageUrl(userId, image.userRelativePath);
    return url;
  }, [image, userId]);

  const maxHeight = (image.size[1] / image.size[0]) * width;

  // const [imageElement, setImageElement] = useState<HTMLImageElement>();
  const state = useContext(StateContext);
  const joinMethod = participant?.participant.joinMethod;
  const [sent, setSent] = useState(false);
  useEffect(() => {
    if (
      image.remoteStatus === "uploaded" &&
      !image.deletedAt &&
      asyncUrlState.value &&
      !sent
    ) {
      setSent(true);
      if (
        joinMethod &&
        "qr" in joinMethod &&
        joinMethod.qr.creatorId === state.authUser?.uid
      ) {
        // here we check if the participant who uploaded the image
        // did join the room from the local userID,
        // in that case we would like to push the image to Figma.
        figma.postImage(image, asyncUrlState.value);
      } else if (participant?.participant && !joinMethod) {
        // if the participant posting this image didn't add the joinMethod
        // then we know that the participant is using an too old app
        figma.postNotification(
          "Please download the latest Grapic iOS app to automatically add images to FigJam."
        );
      }
    }
  }, [
    image,
    asyncUrlState.value,
    userId,
    sent,
    state.authUser?.uid,
    joinMethod,
    participant?.participant,
  ]);

  return (
    <Fragment>
      {image.remoteStatus === "failed" ? (
        <Flex
          css={css`
            width: ${width}px;
            height: ${width}px;
            ${center};
          `}
        >
          Something went wrong uploading the image
        </Flex>
      ) : image.remoteStatus === "uploading" ||
        image.remoteStatus === "uploaded" ? (
        <Flex
          css={css`
            width: ${type === "fixed" ? `${image.size[0]}px` : "100%"};
            height: ${type === "fixed" ? `${image.size[1]}px` : "100%"};
            max-width: ${width}px;
            max-height: ${maxHeight}px;
            ${center};
          `}
          // {...imageProps}
        >
          {image.remoteStatus === "uploading" ? (
            <span>{image.remoteStatus}</span>
          ) : asyncUrlState.value ? (
            // <img
            //   src={asyncUrlState.value}
            //   width={type === "fixed" ? width : "100%"}
            //   height={type === "fixed" ? maxHeight : "100%"}
            //   loading="lazy"
            //   alt={`Snapshot created on ${(image.createdAt || image.createdOn)
            //     .toDate()
            //     .toLocaleDateString()}`}
            //   onClick={() => onSnapshotClick && onSnapshotClick(image)}
            //   {...imageProps}
            // />
            <LazyLoadImage
              // TODO: do something like this to grab the drawn image instead of
              //       re-drawing it on a new canvas, maybe use useRef?
              // onLoad={(element) => {
              //   const imageElement = element.target as HTMLImageElement;
              //   setImageElement(imageElement);
              // }}
              effect="opacity"
              src={asyncUrlState.value} // use normal <img> attributes as props
              width={type === "fixed" ? width : "100%"}
              height={type === "fixed" ? maxHeight : undefined}
              loading="lazy"
              alt={`Snapshot created on ${(image.createdAt || image.createdOn)
                .toDate()
                .toLocaleDateString()}`}
              onClick={() => onSnapshotClick && onSnapshotClick(image)}
              {...imageProps}
            />
          ) : asyncUrlState.error ? (
            <Flex
              css={css`
                width: ${width}px;
                height: ${width}px;
                ${center};
              `}
            >
              Something went wrong loading the image
            </Flex>
          ) : (
            <Fragment />
          )}
        </Flex>
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};

export default GrapicImg;
