import { Global, css } from "@emotion/react";
import "typeface-poppins";
import "typeface-open-sans";

import * as colors from "./utils/colors";
import * as styleUtils from "./utils/styles";

const Styles = () => (
  <Global
    styles={css`
      html {
        box-sizing: border-box;
      }

      *,
      *:before,
      *:after {
        box-sizing: inherit;
      }

      body {
        margin: 0;
        font-family: "Open Sans", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      h1,
      h2,
      h3,
      h4,
      h5 {
        font-family: "Poppins", serif;
        font-weight: bold;
        line-height: 1.1;
        letter-spacing: 1px;
        word-break: break-word;
      }

      button {
        font-family: "Poppins", serif;
      }

      code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
          monospace;
      }

      img {
        margin: 0;
      }

      html,
      body,
      #root {
        height: 100%;
        background: ${colors.LIGHT_BACKGROUND};
      }

      ${styleUtils.darkMode} {
        html,
        body,
        #root {
          background: ${colors.DARK_BACKGROUND};
          color: ${colors.DARK_TEXT};
        }
      }
    `}
  />
);

export default Styles;
