/** @jsxImportSource @emotion/react */

import * as avatars from "@dicebear/avatars";
import * as style from "@dicebear/avatars-initials-sprites";
import { css } from "@emotion/react";

import { bounceInDown } from "../utils/animations";

const DicebearAvatar = (props: {
  className?: string;
  seed: string;
  title?: string;
}) => {
  const svg = avatars.createAvatar(style, {
    seed: props.seed,
    chars: 1,
    radius: 100,
  });

  return (
    <div
      title={props.title || props.seed}
      role="img"
      aria-label={`${props.seed}s avatar`}
      dangerouslySetInnerHTML={{ __html: svg }}
      {...props}
    />
  );
};

interface ProfilePictureProps {
  displayName: string;
  photoURL?: string;
  imgTitle?: string;
  noBounce?: boolean;
}

export const ProfilePicture = (props: ProfilePictureProps) => (
  <div
    css={css`
      width: 2rem;
      height: 2rem;
      ${!props.noBounce &&
      css`
        animation: ${bounceInDown} 1s;
      `}
    `}
  >
    {props.photoURL ? (
      <img
        src={props.photoURL}
        title={props.imgTitle}
        alt={props.displayName}
        width="32"
        height="32"
        referrerPolicy="origin"
        css={css`
          border-radius: 2rem;
        `}
      />
    ) : (
      <DicebearAvatar
        seed={props.displayName || "A"}
        title={props.imgTitle}
        css={css`
          width: 2rem;
          height: 2rem;
        `}
      />
    )}
  </div>
);

export default ProfilePicture;
