import * as colors from "../../utils/colors";

const Arrow = ({
  title,
  color = colors.GRAPIC_BLACK,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M18.0008 8.9998C17.6142 8.61321 16.9874 8.61321 16.6008 8.9998L12.7079 12.8927C12.3174 13.2832 11.6842 13.2832 11.2937 12.8927L7.40078 8.99981C7.01418 8.61321 6.38738 8.61321 6.00078 8.9998V8.9998C5.61418 9.3864 5.61418 10.0132 6.00078 10.3998L11.2937 15.6927C11.6842 16.0832 12.3174 16.0832 12.7079 15.6927L18.0008 10.3998C18.3874 10.0132 18.3874 9.3864 18.0008 8.9998V8.9998Z"
      fill={color}
    />
  </svg>
);

export default Arrow;
