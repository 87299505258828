import { useEffect, useState } from "react";

/**
 * Store a state in the session storage
 * @param key Session storage key
 * @param storageType The type of storage - sessionStorage or localStorage
 */
const useWithStorage = (key: string, storageType: "session" | "local") => {
  let storage = storageType === "session" ? sessionStorage : localStorage;
  const [value, setValue] = useState(storage.getItem(key) || "");

  useEffect(() => {
    if (value) storage.setItem(key, value);
    else storage.removeItem(key);
  }, [value, key, storage]);

  return [value, setValue] as const;
};

export const useCleanOldKeys = () => {
  useEffect(() => {
    // was used to remember room name, removed 2020-05-29
    localStorage.removeItem("room");
    // was used to determine if user had iOS app, removed 2020-11-03
    localStorage.removeItem("didConnectPhone");
    // was used to set browser uuid, removed 2020-11-03
    localStorage.removeItem("uuid");
    // was used to store if the tour (react-joyride) was completed, removed 2020-11-03
    localStorage.removeItem("finishedOnboarding");
  }, []);
};

export default useWithStorage;
