import firebase from "firebase/compat/app";

const FOLDER_USER_IMAGES = "userImages";

const storage = firebase.storage();

/**
 * Get the URL of a file.
 * @param {string} userId User ID of which the file belong to
 * @param {string} path Path to file from the user folder
 */
export const getUserImageUrl = async (userId: string, path: string) => {
  try {
    const downloadURL = await storage
      .ref()
      .child(FOLDER_USER_IMAGES)
      .child(userId)
      .child(path)
      .getDownloadURL();
    return downloadURL as string;
  } catch (error) {
    console.log("Error getting user imge url", error);
    throw error;
  }
};

export const deleteUserImage = async (userId: string, path: string) => {
  try {
    await storage
      .ref()
      .child(FOLDER_USER_IMAGES)
      .child(userId)
      .child(path)
      .delete();
    return true;
  } catch (error) {
    console.log("Error deleting user image", error);
    throw error;
  }
};
