/** @jsxImportSource @emotion/react */

import { useContext } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";

import { Button, Flex } from "./atoms";
import Logo from "./Logo";
import ProfilePicture from "./ProfilePicture";
import StateContext from "../contexts/State";
import * as routes from "../utils/routes";

const Header = () => {
  const state = useContext(StateContext);

  return (
    <Flex
      css={css`
        max-width: 90rem;
        min-height: 4.5rem;
      `}
    >
      <Logo
        css={css`
          padding: 1rem;
          align-self: flex-start;
          flex-grow: 1;
        `}
      />
      {!!state.authUser ? (
        <Flex
          css={css`
            align-items: center;
            padding: 1rem;
          `}
        >
          {/* <Link
            to={routes.HOME}
            css={css`
              margin-right: 1rem;
              color: unset;
            `}
          >
            Home
          </Link> */}
          {/* <Link
            to={routes.GRAPICS}
            css={css`
              margin-right: 1rem;
              color: unset;
            `}
          >
            My Grapics
          </Link> */}
          <Link to={routes.SETTINGS}>
            {state.user?.photoURL ? (
              <img
                src={state.user.photoURL}
                width="32"
                loading="lazy"
                title={`Signed in as ${state.user.email}`}
                alt={`${state.user.email}`}
                css={css`
                  border-radius: 2rem;
                `}
              />
            ) : (
              <ProfilePicture
                displayName={
                  state.user?.displayName ||
                  state.user?.email ||
                  state.authUser.uid
                }
                imgTitle={
                  state.authUser.isAnonymous
                    ? "Signed in as guest"
                    : `Signed in as ${state.user?.email}`
                }
                noBounce
              />
            )}
          </Link>
        </Flex>
      ) : (
        <Button
          css={css`
            margin: 1rem 1rem 0 1rem;
          `}
          onClick={() => state.setSignInModal("signIn")}
        >
          Sign in
        </Button>
      )}
    </Flex>
  );
};

export default Header;
