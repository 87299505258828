/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import * as colors from "../../utils/colors";
import * as styleUtils from "../../utils/styles";

const Share = ({
  title,
  color = colors.GRAPIC_BLACK,
  darkModeColor = colors.GRAPIC_BLACK,
  //   size = 60,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  darkModeColor?: string;
}) => (
  <svg
    width="33"
    height="38"
    viewBox="0 0 33 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M14.8869 5.37436L9.83757 10.4237L8.76034 9.26949L15.0868 3.06235C15.0921 3.05722 15.0972 3.05197 15.1022 3.04661L16.4998 1.5492L17.8974 3.04661C17.9024 3.05197 17.9075 3.05722 17.9127 3.06235L24.2392 9.26949L23.162 10.4237L18.1127 5.37436C17.9697 5.23136 17.7546 5.18858 17.5678 5.26597C17.381 5.34337 17.2591 5.52568 17.2591 5.72791L17.2591 23.2279L15.7404 23.2279L15.7404 5.72791C15.7404 5.52568 15.6186 5.34337 15.4318 5.26597C15.2449 5.18858 15.0299 5.23136 14.8869 5.37436Z"
      // fill={color}
      stroke={color}
      stroke-linejoin="round"
      css={css`
        ${styleUtils.darkMode} {
          stroke: ${darkModeColor};
        }
      `}
    />
    <path
      d="M19.4202 15.1602H25.5C27.1569 15.1602 28.5 16.5033 28.5 18.1602V33.5371C28.5 35.1939 27.1569 36.5371 25.5 36.5371H7.5C5.84315 36.5371 4.5 35.1939 4.5 33.5371V18.1602C4.5 16.5033 5.84315 15.1602 7.5 15.1602H13.4925"
      stroke={color}
      stroke-width="2.5"
      css={css`
        ${styleUtils.darkMode} {
          stroke: ${darkModeColor};
        }
      `}
    />
  </svg>
);

export default Share;
