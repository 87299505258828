import firebase from "firebase/compat/app";

import * as firestore from "./firestore";
import { Firestore } from "../utils/types";

export const doUpdateOrCreateUser = (
  user: firebase.User,
  setUser: React.Dispatch<React.SetStateAction<Firestore.DatabaseUser | null>>
) => {
  let databaseUser: Firestore.DatabaseUser = {
    emailVerified: user.emailVerified,
    uid: user.uid,
  };

  // the value from firebase authentication can always be set
  if (user.email) databaseUser.email = user.email;
  if (user.displayName) databaseUser.displayName = user.displayName;
  if (user.photoURL) databaseUser.photoURL = user.photoURL;
  if (user.metadata.creationTime)
    databaseUser.createdAt = firebase.firestore.Timestamp.fromDate(
      new Date(user.metadata.creationTime)
    );

  firestore
    .updateUser(user.uid, databaseUser)
    .then(() => {
      getDatabaseUser(user, setUser);
    })
    .catch((reason: firebase.FirebaseError) => {
      if (reason.code === "not-found") {
        // the user is new and doesn't exist in the database yet, create user instead

        // Temporarily give beta access to all users that create an account
        // TODO: remove this
        if (user.metadata.creationTime) {
          databaseUser.betaAccessAt = databaseUser.createdAt =
            firebase.firestore.Timestamp.fromDate(
              new Date(user.metadata.creationTime)
            );
        }

        if (!user.metadata.creationTime) {
          databaseUser.betaAccessAt =
            firebase.firestore.FieldValue.serverTimestamp();
          databaseUser.createdAt =
            firebase.firestore.FieldValue.serverTimestamp();
        }

        databaseUser.firstSeenOnWeb =
          firebase.firestore.FieldValue.serverTimestamp();

        firestore.createUser(user.uid, databaseUser).then(() => {
          getDatabaseUser(user, setUser);
        });
      }
    });
};

export const getDatabaseUser = async (
  authUser: firebase.User,
  setUser: React.Dispatch<React.SetStateAction<Firestore.DatabaseUser | null>>
) => {
  try {
    const databaseUser = await firestore.getUser(authUser.uid);

    if (databaseUser) {
      databaseUser.uid = authUser.uid;
      setUser(databaseUser);
      getDatabaseUserPushTokens(authUser).then((pushTokens) => {
        if (pushTokens) {
          setUser({ fcmPushTokens: pushTokens, ...databaseUser });
        }
      });
      setFirstSeenOnWeb(authUser, databaseUser);
    }
  } catch (error) {
    console.log("Error getting document:", error);
  }
};

const getDatabaseUserPushTokens = async (authUser: firebase.User) => {
  const pushTokens = await firestore.getUserPushTokens(authUser.uid);
  return pushTokens;
};

const setFirstSeenOnWeb = async (
  authUser: firebase.User,
  databaseUser: Firestore.DatabaseUser
) => {
  if (!databaseUser.firstSeenOnWeb) {
    await firestore.updateUser(authUser.uid, {
      firstSeenOnWeb: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
};
