/** @jsxImportSource @emotion/react */

import { Fragment, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Global, css } from "@emotion/react";
import Modal from "react-modal";
import firebase from "firebase/compat/app";
import * as firebaseui from "firebaseui";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import StateContext from "../contexts/State";
import * as analytics from "../firebase/analytics";
import * as firebaseConfig from "../firebase/config";
import * as firebaseUtils from "../firebase/utils";
import * as colors from "../utils/colors";
import * as intercom from "../utils/intercom";
import * as routes from "../utils/routes";
import * as styleUtils from "../utils/styles";

Modal.setAppElement(document.getElementById("root") as HTMLElement);

const SignInModal = () => {
  const history = useHistory();
  const location = useLocation();
  const state = useContext(StateContext);
  const { signInModal, setSignInModal } = state;

  const modalIsOpen =
    signInModal === "signIn" ||
    signInModal === "signInWithAnonymous" ||
    signInModal === "upgradeFromAnonymous";

  const emailLinkRedirect = () => {
    const searchParams = new URLSearchParams(location.search);
    const redirectToPath = searchParams.get(routes.QUERY_SIGN_IN_REDIRECT);
    if (redirectToPath) {
      history.push(redirectToPath);
    }
  };

  return (
    <Fragment>
      <Global
        styles={css`
          .modal-content {
            position: absolute;
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            border: none;
            overflow: auto;
            border-radius: 1.8rem;
            outline: none;
            padding: 20px;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            width: 100%;
            max-width: 29rem;
            box-shadow: rgba(0, 0, 0, 0.09) 2px 5px 15px;
            background: rgb(255, 255, 255);

            ${styleUtils.darkMode} {
              color: ${colors.GRAPIC_BLACK};
            }
          }

          .modal-overlay {
            position: fixed;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;

            z-index: 3;

            background-color: rgba(0, 0, 0, 0.4);
          }

          .firebaseui-container {
            max-width: unset !important;
          }
          .firebaseui-container,
          .firebaseui-container button {
            font-family: "Poppins", serif !important;
          }
          .firebaseui-container p,
          .firebaseui-container label,
          .firebaseui-container input {
            font-family: "Open Sans", sans-serif !important;
          }
          .firebaseui-container h1 {
            font-weight: bold;
          }
          .mdl-shadow--2dp {
            box-shadow: none !important;
          }
          .firebaseui-idp-password,
          .firebaseui-form-actions
            .mdl-button--raised.mdl-button--colored.firebaseui-button {
            background: ${colors.GRAPIC_TURQUOISE} !important;
          }
          .firebaseui-form-actions .mdl-button--primary {
            color: ${colors.GRAPIC_TURQUOISE} !important;
          }
          .firebaseui-label:after {
            background-color: ${colors.GRAPIC_TURQUOISE} !important;
          }
          .firebaseui-email-sent {
            filter: brightness(0.7);
          }
          .firebaseui-card-footer {
            margin-top: 2rem;
          }
        `}
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          // if user is joining room, closing the modal shouldn't be possible
          // TODO: it should't be possible to close on /new either
          if (signInModal !== "signInWithAnonymous") {
            setSignInModal("closed");
          }
        }}
        className="modal-content"
        overlayClassName="modal-overlay"
        contentLabel="Sign in modal"
        style={{ overlay: { zIndex: 10 } }}
      >
        <div
          css={css`
            padding: 0 1.5rem 0.1rem;
            text-align: center;
          `}
        >
          <h2>
            {signInModal === "signInWithAnonymous"
              ? "Join Grapic room"
              : "Sign in to Grapic"}
          </h2>
          <p>
            {signInModal === "signInWithAnonymous"
              ? "Sign in or continue as guest."
              : "Create rooms, join rooms with push and more."}
          </p>
        </div>
        <StyledFirebaseAuth
          firebaseAuth={firebase.auth()}
          uiConfig={{
            // should be "popup", workaround for https://github.com/firebase/firebaseui-web-react/issues/88
            signInFlow: firebase
              .auth()
              .isSignInWithEmailLink(window.location.href)
              ? "redirect"
              : "popup",
            signInOptions: [
              // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              {
                // Google provider must be enabled in Firebase Console to support one-tap
                // sign-up.
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                // Required to enable ID token credentials for this provider.
                // This can be obtained from the Credentials page of the Google APIs
                // console. Use the same OAuth client ID used for the Google provider
                // configured with GCIP or Firebase Auth.
                clientId: firebaseConfig.GoogleOAuthCliendId,
              },
              "apple.com",
              {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                signInMethod:
                  firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
                // Allow the user the ability to complete sign-in cross device, including
                // the mobile apps specified in the ActionCodeSettings object below.
                // forceSameDevice: false,
                emailLinkSignIn: () => ({
                  url: `${routes.baseUrl}?${routes.QUERY_SIGN_IN_REDIRECT}=${location.pathname}`,
                  dynamicLinkDomain: routes.dynamicLinkDomain,
                  handleCodeInApp: true,
                  // iOS: { bundleId: routes.iosBundleId },
                }),
              },
              signInModal === "signInWithAnonymous"
                ? firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
                : "",
            ],
            autoUpgradeAnonymousUsers: true,
            // Enable Google one-tap sign-up. To disable set firebaseui.auth.CredentialHelper.NONE
            credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
            privacyPolicyUrl: routes.privacyPolicyUrl,
            tosUrl: routes.termsOfServiceUrl,
            callbacks: {
              // uiShown // TODO: enable loading spinner while loading ui
              signInFailure: async (error) => {
                if (
                  error.code !== "firebaseui/anonymous-upgrade-merge-conflict"
                ) {
                  console.log("sign in error", error);
                  return Promise.resolve();
                }
                // handling a user upgrade merge conflict
                // if anonymous users can store stuff in database
                // we should merge that data here as well
                // https://github.com/firebase/firebaseui-web/#handling-anonymous-user-upgrade-merge-conflicts

                // delete the anonymous user
                const anonymousUser = firebase.auth().currentUser;
                if (anonymousUser) {
                  await anonymousUser.delete();
                } else {
                  console.error("Can't delete the anonymous user");
                }
                // the new credential the user tried to sign in with
                const newCredential = error.credential;
                // sign in as the new user
                await firebase.auth().signInWithCredential(newCredential);
                setSignInModal("closed");
                emailLinkRedirect();
                return Promise.resolve();
              },
              signInSuccessWithAuthResult: (
                authResult: firebase.auth.UserCredential
              ) => {
                const { user, additionalUserInfo } = authResult;
                const signInMethod =
                  additionalUserInfo?.providerId ===
                  firebase.auth.EmailAuthProvider.PROVIDER_ID // "password"
                    ? firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD // "emailLink"
                    : !additionalUserInfo?.providerId && user?.isAnonymous
                    ? firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID // "anonymous"
                    : additionalUserInfo?.providerId;

                analytics.logSignedInEvent(signInMethod);

                if (user && !user.isAnonymous) {
                  firebaseUtils.doUpdateOrCreateUser(user, state.setUser);
                  // we call this here and in authStateChange because isAnonymous is not set correctly there
                  intercom.updateUser(user);
                }
                if (additionalUserInfo) {
                  state.setIsNewUser(additionalUserInfo.isNewUser);
                }
                setSignInModal("closed");
                emailLinkRedirect();
                return false;
              },
            },
          }}
        />
      </Modal>
    </Fragment>
  );
};

export default SignInModal;
