/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ExternalLink } from "../atoms";
import twitterLogo from "../../images/social/Twitter_Logo_Blue.svg";

const TwitterLink = (props: { className?: string }) => (
  <ExternalLink to="https://twitter.com/GrapicApp" {...props}>
    <img
      src={twitterLogo}
      loading="lazy"
      title="@GrapicApp at Twitter"
      alt="Grapic at Twitter"
      css={css`
        width: 3rem;
      `}
    />
  </ExternalLink>
);

export default TwitterLink;
