import { useContext } from "react";
import { useAsync } from "react-use";

import StateContext from "../contexts/State";
import WebRoomContext from "../contexts/WebRoom";
import * as functions from "../firebase/functions";

/**
 * Fetch access token to be used with Twilio
 */
const useTwilioCredentials = () => {
  const state = useContext(StateContext);
  const webRoom = useContext(WebRoomContext);

  const fetchToken = useAsync(async () => {
    if (!webRoom?.room.id || !state.authUser?.uid || !state.clientId) return;
    // TODO:  crash when upgrading sign in (in room) results in merge conflict
    //        since it will result in a mismatch between the uid in activeParticipants
    //        and the new authUser.uid.
    const twilioCredentials = await functions.fetchTwilioCredentials({
      clientId: state.clientId,
      roomId: webRoom.room.id,
    });
    return twilioCredentials;
    // authUser.uid is also in this dependecy list since it affects the creation of the access token
  }, [state.clientId, state.authUser?.uid, webRoom?.room.id]);

  return fetchToken.value;
};

export default useTwilioCredentials;
