/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import { Button, ExternalLink, Flex, TextButton } from "./atoms";
import TypeformButton from "./TypeformButton";
import { ReactComponent as DownloadOnAppStore } from "../images/apps/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg";

// export const appStoreUrl = encodeURI(`https://apps.apple.com/app/id1515109130`);
export const appStoreUrl = encodeURI(`https://link.grapic.co/app-store-app`);

const AppWaitlistCTA = ({
  shownInRoom = false,
  ...rest
}: {
  className?: string;
  shownInRoom?: boolean;
}) => {
  return (
    <Flex
      css={css`
        flex-direction: ${shownInRoom ? `column` : `row`};
        flex-wrap: wrap;
        ${shownInRoom &&
        `
        margin: 0 0 1rem 1rem;
        `}
      `}
      {...rest}
    >
      <ExternalLink
        to={appStoreUrl}
        css={css`
          display: flex;
          ${!shownInRoom &&
          `
          margin: 0 2rem 2rem 0;
          `}
        `}
      >
        <DownloadOnAppStore
          title="Download on the App Store"
          css={css`
            ${shownInRoom
              ? `
              height: 2.25rem;
              width: auto;
              `
              : `
              height: 52px;
              width: 154px;
              `}
          `}
        />
      </ExternalLink>
      {shownInRoom ? (
        <TypeformButton>
          <TextButton
            css={css`
              margin: 0.5rem 0 0 0;
              text-decoration: underline;
              font-size: 0.9rem;
            `}
          >
            Android waitlist
          </TextButton>
        </TypeformButton>
      ) : (
        <TypeformButton>
          <Button primary black inverted noHover>
            Android waitlist
          </Button>
        </TypeformButton>
      )}
    </Flex>
  );
};

export default AppWaitlistCTA;
