import * as colors from "../../utils/colors";

const Link = ({
  title,
  color = colors.GRAPIC_BLACK,
  size = 28,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M13.7212 19.5705L15.444 17.8217C13.7342 17.6922 12.6202 17.174 11.7782 16.332C9.51129 14.0652 9.52424 10.8527 11.7652 8.61171L15.9881 4.38883C18.255 2.1349 21.4416 2.12196 23.7085 4.38883C25.9754 6.65571 25.9494 9.85526 23.7085 12.0962L21.1695 14.6351C21.5322 15.4641 21.61 16.4227 21.4804 17.2647L25.2888 13.4693C28.3847 10.3604 28.4105 5.96916 25.2758 2.82145C22.1281 -0.326268 17.7239 -0.300375 14.615 2.8085L10.1978 7.23863C7.08896 10.3475 7.06307 14.7517 10.2108 17.8865C11.0269 18.7026 12.0631 19.2855 13.7212 19.5705ZM14.045 8.89669L12.3222 10.6454C14.0321 10.7879 15.1461 11.2931 15.9881 12.1351C18.255 14.402 18.242 17.6144 16.0011 19.8554L11.7652 24.0783C9.51129 26.3322 6.32471 26.3452 4.05784 24.0913C1.79094 21.8114 1.80391 18.6248 4.05784 16.3709L6.59674 13.832C6.23404 13.0159 6.14336 12.0444 6.28585 11.2024L2.47748 14.9978C-0.618418 18.1067 -0.644326 22.5109 2.49046 25.6457C5.63817 28.7934 10.0424 28.7675 13.1383 25.6716L17.5684 21.2285C20.6773 18.1196 20.7032 13.7154 17.5555 10.5806C16.7394 9.76458 15.7031 9.18166 14.045 8.89669Z"
      fill={color}
    />
  </svg>
);

export default Link;
