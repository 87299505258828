import { Fragment } from "react";
import { Link } from "react-router-dom";
import * as twilio from "twilio-video";

import { RoomMessage } from "./roomAtoms";
import { Button } from "../atoms";
import * as twilioErrors from "../../twilio/errors";
import * as routes from "../../utils/routes";

interface TwilioRoomErrorProps {
  roomError: twilio.TwilioError;
  reconnect: () => void;
}

const TwilioRoomError = ({ roomError, reconnect }: TwilioRoomErrorProps) => (
  <RoomMessage>
    {roomError.code === twilioErrors.Connection.UnableToCreateRoom ? (
      <Fragment>
        <p>There is no room with this ID.</p>
        <Link to={routes.HOME}>
          <Button>Go back home</Button>
        </Link>
      </Fragment>
    ) : (
      <Fragment>
        <p>
          {roomError.code ===
          twilioErrors.Connection.RoomMaxParticipantsExceeded
            ? `Oh darn, the room has reached the maximum number of allowed participants.`
            : `Oh darn, an unexpected error happened when connecting.`}
        </p>
        <Button onClick={reconnect}>Try to connect again</Button>
      </Fragment>
    )}
  </RoomMessage>
);

export default TwilioRoomError;
