import * as colors from "../../utils/colors";

const Fullscreen = ({
  title,
  color = colors.GRAPIC_WHITE,
  size = 40,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M36.6663 3.33594H23.333V6.66927H30.9997L22.9997 14.6693L25.333 17.0026L33.333 9.0026V16.6693H36.6663V3.33594ZM14.6663 23.0026L6.66634 31.0026V23.3359H3.33301V36.6693H16.6663V33.3359H8.99968L16.9997 25.3359L14.6663 23.0026V23.0026Z"
      fill={color}
    />
  </svg>
);

export default Fullscreen;
