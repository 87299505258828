/** @jsxImportSource @emotion/react */

import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";

import { RoomButton } from "./roomAtoms";
import Wave from "../../images/icons/Wave";
import * as routes from "../../utils/routes";

const LeaveButton = ({
  buttonText = "Leave",
  disabled,
  ...rest
}: {
  buttonText?: string;
  className?: string;
  disabled?: boolean;
}) => {
  const history = useHistory();

  return (
    <RoomButton
      noHover
      disabled={disabled}
      onClick={() => history.push(routes.HOME)}
      {...rest}
    >
      <Wave
        color={disabled ? "gray" : undefined}
        css={css`
          width: 1.5rem;
          height: 1.5rem;
          margin-bottom: 0.2rem;
        `}
      />
      <span>{buttonText}</span>
    </RoomButton>
  );
};

export default LeaveButton;
