/** @jsxImportSource @emotion/react */

import { Fragment, useContext, useState, useEffect } from "react";
import { Global, css } from "@emotion/react";
import { useIdle } from "react-use";
import * as sentry from "@sentry/react";

import Presentation from "./Presentation";
import PresentModal from "./PresentModal";
import {
  InfoMessageBox,
  InfoMessageText,
  Backdrop,
  RoomMessage,
  IdeaImage,
  ConnectionError,
} from "./roomAtoms";
import RoomFooter from "./RoomFooter";
import RoomHeader from "./RoomHeader";
import RoomRightBar from "./RoomRightBar";
import TwilioRoomError from "./TwilioRoomError";

import { Button, HighlightText, TextButton, FlexCol, FlexWrap } from "../atoms";
import Loading from "../Loading";

import StateContext from "../../contexts/State";
import TwilioContext from "../../contexts/Twilio";
import WebRoomContext from "../../contexts/WebRoom";

import useParticipants from "../../hooks/useParticipants";
import useRoomState from "../../hooks/useRoomState";
import useAllPublications from "../../hooks/useAllPublications";
import usePublications from "../../hooks/usePublications";
import Circle from "../../images/icons/Circle";
import * as twilioUtils from "../../twilio/utils";
import * as colors from "../../utils/colors";
import * as routes from "../../utils/routes";
import * as styleUtils from "../../utils/styles";
import TypeformButton from "../TypeformButton";

interface TwilioRoomProps {
  embed?: boolean;
}

const TwilioRoom = (props: TwilioRoomProps) => {
  const state = useContext(StateContext);
  const webRoom = useContext(WebRoomContext);
  // we can safely cast this since we do a null-check in the WebRoom render
  // might be better to directly use props instead
  const webRoomId = webRoom?.room.id!;
  const { room, roomError, reconnect, objective } = useContext(TwilioContext);
  const roomState = useRoomState();
  const participants = useParticipants();
  const allPublications = useAllPublications();
  // const [canPlay, checkCanPlay] = useCanAutoPlay(allPublications.length);

  const localParticipant = room?.localParticipant;
  const phoneParticipants = participants.filter((participant) =>
    twilioUtils.isPhoneParticipant(participant)
  );

  const userPhoneParticipant = webRoom?.participants.find(
    (participant) =>
      participant.participant?.uid === state.authUser?.uid &&
      participant.participant?.deviceType === "ios"
  );

  const didConnectPhone = !!state.user?.firstSeenOnMobile;

  const [userWasFirstInRoom, setUserWasFirstInRoom] = useState<
    boolean | undefined
  >(undefined);

  // Present modal start //
  const [presentModalIsOpen, setPresentModalIsOpen] = useState(false);
  useEffect(() => {
    if (room && userWasFirstInRoom === undefined) {
      const firstInRoom = room.participants.size === 0;
      setUserWasFirstInRoom(firstInRoom);
      setPresentModalIsOpen(firstInRoom);
    }
  }, [room, userWasFirstInRoom]);

  const userPhonePublications = usePublications(
    userPhoneParticipant?.twilioParticipant
  );
  const userGrapicsInRoom = !!webRoom?.participants
    ?.filter((participant) => participant.participant?.uid === state.user?.uid)
    ?.flatMap((participant) => participant.participant?.grapics)
    .filter((grapic) => !!grapic).length;
  useEffect(() => {
    if (userPhonePublications.length > 0 || userGrapicsInRoom) {
      setPresentModalIsOpen(false);
    }
  }, [userPhonePublications.length, userGrapicsInRoom]);

  // const step = !!userPhoneParticipant ? 2 : 1;

  // Present modal end //

  // Disconnect from room when:
  // - user has been idle for 10 minutes, and
  // - there is no phones connected to the room
  const isIdle = useIdle(1000 * 60 * 10);
  useEffect(() => {
    if (isIdle && phoneParticipants.length <= 0) {
      room?.disconnect();
    }
  }, [room, isIdle, phoneParticipants.length]);

  useEffect(() => {
    const roomContext = room
      ? {
          name: room.name,
          sid: room.sid,
          localParticipant: {
            identity: room.localParticipant.identity,
            sid: room.localParticipant.sid,
          },
          participantIndentities: Array.from(
            room.participants,
            ([key, value]) => value.identity
          ),
        }
      : null;
    sentry.setContext("twilio room", roomContext);
    return () => {
      sentry.setContext("twilio room", null);
    };
  }, [room, participants.length]);

  const publicationsInRoom: boolean = allPublications.length > 0;
  const grapicsInRoom = !!webRoom?.participants
    ?.flatMap((participant) => participant.participant?.grapics)
    .filter((grapic) => !!grapic).length;

  const contentInRoom: boolean = publicationsInRoom || grapicsInRoom;

  // TODO: should we always have this?
  useEffect(() => {
    if (contentInRoom) setPresentModalIsOpen(false);
  }, [contentInRoom]);

  const roomUrl = `${window.location.protocol}//${window.location.host}${routes.ROOM_BASE}/${webRoomId}`;

  return room ? (
    <FlexCol
      css={css`
        min-height: 100%;
      `}
    >
      <Global
        styles={css`
          html,
          body,
          #root {
            overflow: hidden;
          }
        `}
      />
      <Backdrop />
      {/* HEADER */}
      <RoomHeader
        embed={props.embed}
        participants={participants}
        localParticipant={localParticipant}
      />
      {/* BODY  */}
      <FlexCol
        css={css`
          flex-grow: 1;
          justify-content: center;
          text-align: center;

          position: absolute;
          top: ${contentInRoom ? "2rem" : "0"};
          bottom: 0;
          right: 0;
          left: 0;
        `}
      >
        {roomState === "disconnected" && objective === "disconnected" && (
          <RoomMessage>
            <p
              css={css`
                display: flex;
                font-weight: 600;
                top: 10%;
              `}
            >
              <Circle
                color={colors.GRAPIC_RED}
                css={css`
                  align-self: center;
                  margin-right: 1rem;
                `}
              />
              You disconnected from the room.
            </p>
            <Button onClick={reconnect} yellow noHover primary>
              Reconnect
            </Button>
          </RoomMessage>
        )}

        {(roomState === "reconnecting" ||
          (roomState === "disconnected" && objective === "connected")) && (
          <ConnectionError>
            <span
              css={css`
                display: flex;
                font-weight: 600;
                top: 10%;
              `}
            >
              <Circle
                color={colors.GRAPIC_YELLOW}
                css={css`
                  align-self: center;
                  margin-right: 1rem;
                `}
              />
              Lost connection, trying to reconnect...
            </span>
          </ConnectionError>
        )}

        {(roomState === "connected" || roomState === "reconnecting") && (
          <Fragment>
            {/* Starting screen, when no one has a phone connected */}
            {!contentInRoom && (
              <InfoMessageBox
                css={css`
                  padding: 0;
                `}
              >
                <div
                  css={css`
                    padding: 2rem 2rem 0;
                  `}
                >
                  <InfoMessageText>
                    {userWasFirstInRoom ? (
                      <span>
                        Space for <HighlightText>great ideas</HighlightText>
                      </span>
                    ) : (
                      <span>
                        Waiting for <HighlightText>great ideas</HighlightText>{" "}
                        to appear
                      </span>
                    )}
                  </InfoMessageText>
                  <IdeaImage />
                </div>
                {!userWasFirstInRoom && !state.user?.firstSeenOnMobile && (
                  <div
                    css={css`
                      width: 100%;
                      background: ${colors.GRAPIC_YELLOW};
                      ${styleUtils.darkMode} {
                        color: ${colors.GRAPIC_BLACK};
                      }
                      padding: 1.5rem;
                      border-bottom-left-radius: inherit;
                      border-bottom-right-radius: inherit;
                    `}
                  >
                    <span>{`Your turn next time? `}</span>
                    <TypeformButton>
                      <TextButton
                        css={css`
                          font-weight: bold;
                          text-decoration: underline;
                          color: ${colors.GRAPIC_BLACK} !important;
                        `}
                      >{`Get the Grapic app`}</TextButton>
                    </TypeformButton>
                  </div>
                )}
              </InfoMessageBox>
            )}

            {/* Present modal */}
            <PresentModal
              presentModalIsOpen={presentModalIsOpen}
              setPresentModalIsOpen={setPresentModalIsOpen}
              userPhoneParticipant={userPhoneParticipant}
              webRoomId={webRoomId}
              localParticipantIdentity={localParticipant?.identity}
              embed={!!props.embed}
            />

            {/* {publicationsInRoom && !canPlay && (
              <Button
                onClick={checkCanPlay}
                css={{ maxWidth: "25rem", margin: "auto" }}
              >
                Show stream
              </Button>
            )} */}

            <FlexWrap
              css={css`
                margin: auto 1rem;
                z-index: 1;
                justify-content: space-between;

                ${styleUtils.hasAtLeastChilds(2)} {
                  flex-basis: 49.5%;
                }
                ${styleUtils.hasAtLeastChilds(3)} {
                  video,
                  #images {
                    max-height: 39vh !important;
                  }
                }
                ${styleUtils.hasAtLeastChilds(5)} {
                  flex-basis: 32.5%;
                }
                ${styleUtils.hasAtLeastChilds(7)} {
                  flex-basis: 24%;
                }
                ${styleUtils.hasAtLeastChilds(9)} {
                  flex-basis: 33%;
                  video,
                  #images {
                    max-height: 25vh !important;
                  }
                }
              `}
            >
              {webRoom?.participants?.map((participant) => (
                <Presentation
                  key={
                    participant.participant?.clientId ||
                    participant.twilioParticipant?.identity
                  }
                  participant={participant}
                  embed={props.embed}
                  localParticipant={room.localParticipant}
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: 100%;
                    margin-bottom: 0.5%;
                  `}
                />
              ))}
            </FlexWrap>
          </Fragment>
        )}
      </FlexCol>

      <RoomRightBar
        embed={props.embed}
        presentModalIsOpen={presentModalIsOpen}
        setPresentModalIsOpen={setPresentModalIsOpen}
        userPhoneParticipant={userPhoneParticipant}
        roomUrl={roomUrl}
      />

      {/* FOOTER */}
      <RoomFooter
        embed={props.embed}
        didConnectPhone={didConnectPhone}
        presentModalIsOpen={presentModalIsOpen}
        setPresentModalIsOpen={setPresentModalIsOpen}
        userPhoneParticipant={userPhoneParticipant}
        allPublications={allPublications}
        roomUrl={roomUrl}
      />
    </FlexCol>
  ) : roomError ? (
    <TwilioRoomError roomError={roomError} reconnect={reconnect} />
  ) : (
    <Loading />
  );
};

export default TwilioRoom;
