import styled from "@emotion/styled";
import * as colors from "../utils/colors";
import * as styleUtils from "../utils/styles";

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  padding: 1rem 0.5rem;
  ${styleUtils.mq[1]} {
    padding: 1rem;
  }
  justify-content: space-between;
  position: relative;
  align-self: stretch;
  max-height: 12rem;
  position: absolute;
  height: 100%;
`;

export const Step = styled.div`
  background: ${colors.GRAPIC_YELLOW};
  border-radius: 0.8rem;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2rem;
  color: ${colors.GRAPIC_BLACK};
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", serif;

  ${(props: { done?: boolean }) =>
    props.done
      ? `
      filter: opacity(1);
      `
      : `
      filter: opacity(0.5);
      `}

  ${(props: { line?: boolean; done?: boolean }) =>
    props.line &&
    `
      &:after {
        position: absolute;
        content: close-quote;
        top: 4rem;
        height: 2rem;
        width: 2px;
        background: ${colors.GRAPIC_BROWN};
      }

      &:last-child:after {
        content: none;
      }
      `}
`;

const Stepper = ({ step }: { step: number }) => (
  <StepperWrapper>
    {step <= 1 && (
      <Step done={step >= 1} line>
        1
      </Step>
    )}
    <Step done={step >= 2} line>
      2
    </Step>
  </StepperWrapper>
);

export default Stepper;
