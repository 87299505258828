import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/performance";
import "firebase/compat/storage";

import * as firebaseConfig from "./config";

firebase.initializeApp(firebaseConfig.appConfig);
firebase.analytics();
firebase.performance();
