import * as colors from "../../utils/colors";

const Circle = ({
  title,
  color = colors.GRAPIC_GREEN,
  size = 16,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <circle cx={size / 2} cy={size / 2} r={size / 2} fill={color} />
  </svg>
);

export default Circle;
