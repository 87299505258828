import * as twilio from "twilio-video";
import * as functions from "../firebase/functions";
import * as types from "../utils/types";

/**
 * Connect to a Twilio room with credentials
 */
export const connect = (
  twilioCredentials: functions.TwilioCredentialsResponse
) => {
  return twilio.connect(twilioCredentials.accessToken, {
    name: twilioCredentials.roomName,
    // Disable local audio
    audio: false,
    // Disable local video
    video: false,
    bandwidthProfile: {
      video: {
        // Giving full priority to one remote video track
        mode: "presentation",
      },
    },
  });
};

/**
 * Disconnecting from the Twilio room if it is connected
 */
export const disconnect = (room: twilio.Room | null) => {
  if (room?.state === "connected") {
    room.localParticipant.tracks.forEach((trackPublication) =>
      (trackPublication.track as types.LocalTrack).stop()
    );
    return room.disconnect();
  } else {
    return null;
  }
};
