import * as colors from "../../utils/colors";

const PictureInPicture = ({
  title = `Picture-in-picture`,
  color = colors.GRAPIC_WHITE,
  size = 48,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
  size?: number;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M38 22H22v11.99h16V22zm8 16V9.96C46 7.76 44.2 6 42 6H6C3.8 6 2 7.76 2 9.96V38c0 2.2 1.8 4 4 4h36c2.2 0 4-1.8 4-4zm-4 .04H6V9.94h36v28.1z"
      fill={color}
    />
  </svg>
);

export default PictureInPicture;
