/** @jsxImportSource @emotion/react */

import { Fragment } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { InfoMessageBox, InfoMessageText, HoverImage } from "./roomAtoms";
import SendToPhoneButton from "./SendToPhoneButton";

import { HighlightText, Flex, FlexCol, Triangle, TextButton } from "../atoms";
import QRCode from "../QRCode";

import Present from "../../images/icons/Present";
import * as colors from "../../utils/colors";
import * as styleUtils from "../../utils/styles";
import * as types from "../../utils/types";

const PresentBox = styled(InfoMessageBox)`
  max-width: 32rem;
  padding: 0;
  ${styleUtils.mq[1]} {
    right: 8rem;
  }
`;

const PresentTriangle = styled(Triangle)`
  position: absolute;

  bottom: -0.75rem;
  left: 20%;
  transform: rotate(180deg) scale(2);

  ${styleUtils.mq[0]} {
    bottom: unset;
    left: unset;
    right: -0.75rem;
    top: 8rem;
    ${styleUtils.darkMode} {
      top: 10.5rem;
    }
    transform: rotate(90deg) scale(3);
  }
`;

const PresentModalTitle = (props: {
  setPresentModalIsOpen: (presentModalIsOpen: boolean) => void;
}) => (
  <div
    css={css`
      background: ${colors.GRAPIC_YELLOW};
      width: 100%;
      border-radius: 1.8rem 1.8rem 0 0;
      padding: 1rem;
    `}
  >
    <InfoMessageText
      css={css`
        margin: 0;
        text-align: left;
        display: flex;
        align-items: center;
      `}
    >
      <Present
        css={css`
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0.5rem;
        `}
      />
      <HighlightText
        css={css`
          ${styleUtils.darkMode} {
            color: ${colors.GRAPIC_BLACK};
          }

          text-align: center;
          ${styleUtils.mq[0]} {
            text-align: left;
          }
          flex-grow: 1;
        `}
      >
        Connect your phone to present
      </HighlightText>
      <TextButton
        onClick={() => props.setPresentModalIsOpen(false)}
        css={css`
          font-weight: bold;
          margin: 0 0.5rem;
          ${styleUtils.darkMode} {
            color: ${colors.GRAPIC_BLACK};
          }
        `}
      >
        X
      </TextButton>
    </InfoMessageText>
  </div>
);

const PresentModalBody = styled(Flex)`
  padding: 0.5rem 1rem 1rem 1rem;
  ${styleUtils.mq[0]} {
    padding: 1rem 2rem 2rem 2rem;
  }
`;

const PresentModal = (props: {
  presentModalIsOpen: boolean;
  setPresentModalIsOpen: (presentModalIsOpen: boolean) => void;
  userPhoneParticipant?: types.State.RoomParticipant;
  webRoomId: string;
  localParticipantIdentity?: string;
  embed: boolean;
}) => {
  return (
    <Fragment>
      {props.presentModalIsOpen && (
        <PresentBox>
          <PresentTriangle />
          <PresentModalTitle
            setPresentModalIsOpen={props.setPresentModalIsOpen}
          />
          <PresentModalBody>
            {/* <Stepper step={step} /> */}
            {!props.userPhoneParticipant ? (
              // Connection screen with QR code
              <FlexCol
                css={css`
                  align-items: center;
                  margin: 0 1rem;
                `}
              >
                <InfoMessageText>
                  <HighlightText>To present</HighlightText> your notebook or
                  whiteboard, scan the QR code with your iPhone
                </InfoMessageText>
                <QRCode
                  localParticipantIdentity={
                    props.localParticipantIdentity || ""
                  }
                  room={props.webRoomId}
                />
                {!props.embed && (
                  <Fragment>
                    <InfoMessageText
                      css={css`
                        margin: 1rem 0;
                      `}
                    >
                      or
                    </InfoMessageText>
                    <SendToPhoneButton />
                  </Fragment>
                )}
              </FlexCol>
            ) : (
              // Pin the frame screen
              <div
                css={css`
                  margin: 0 auto;
                `}
              >
                <FlexCol>
                  <InfoMessageText>
                    With your phone,{" "}
                    <HighlightText>pin the frame</HighlightText> to your
                    notebook or whiteboard
                  </InfoMessageText>
                  <HoverImage />
                </FlexCol>
              </div>
            )}
          </PresentModalBody>
        </PresentBox>
      )}
    </Fragment>
  );
};

export default PresentModal;
