import { Fragment } from "react";
import * as twilio from "twilio-video";

import Publication from "./Publication";
import usePublications from "../../hooks/usePublications";
import * as twilioUtils from "../../twilio/utils";

interface ParticipantProps {
  participant: twilio.RemoteParticipant;
  localParticipant: twilio.LocalParticipant;
  embed?: boolean;
}

const Participant = (props: ParticipantProps) => {
  const publications = usePublications(props.participant);
  const isUserPhoneParticipant = twilioUtils.isPhoneParticipantOf(
    props.participant,
    props.localParticipant
  );

  return (
    <Fragment>
      {publications.map((publication) => (
        <Publication
          key={publication.trackSid}
          publication={publication}
          participant={props.participant}
          isUserPhoneParticipant={isUserPhoneParticipant}
          embed={props.embed}
        />
      ))}
    </Fragment>
  );
};

export default Participant;
