/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";

import { FlexCol } from "../atoms";
import NotSupported from "../NotSupported";
import LeaveButton from "./LeaveButton";

const RoomError = (props: {
  reason: "browser_not_supported" | "not_found";
}) => {
  return (
    <FlexCol
      css={css`
        min-height: 100%;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        text-align: center;
      `}
    >
      {props.reason === "browser_not_supported" ? (
        <NotSupported />
      ) : (
        <p>No room found with this ID</p>
      )}
      <LeaveButton buttonText="Go back" />
    </FlexCol>
  );
};

export default RoomError;
