/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { ExternalLink } from "../atoms";
import twitterLogo from "../../images/social/Twitter_Logo_Blue.svg";

const TwitterShare = (props: { className?: string }) => (
  <ExternalLink
    to={`https://twitter.com/intent/tweet/?text=${encodeURI(
      `@GrapicApp is the old-new way to remote whiteboard with your colleagues, check it out!`
    )}&url=${encodeURI(
      `https://www.grapic.co`
    )}&hashtags=remotework,whiteboard,AugmentedReality`}
    css={css`
      font-weight: 600;
      text-decoration: none;
      display: flex;
      align-items: center;
    `}
    {...props}
  >
    <img
      src={twitterLogo}
      loading="lazy"
      title="@GrapicApp at Twitter"
      alt="Twitter logo"
      css={css`
        width: 2rem;
      `}
    />
    Tweet
  </ExternalLink>
);

export default TwitterShare;
