/** @jsxImportSource @emotion/react */

import React, { useContext, useState, Fragment } from "react";
import { useHistory } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { RoughNotation } from "react-rough-notation";
import * as sentry from "@sentry/react";

import {
  Box,
  Button,
  Input,
  TextButton,
  Flex,
  FlexCol,
  ExternalLink,
} from "./atoms";
import AppWaitlistCTA, { appStoreUrl } from "./AppWaitlistCTA";
import Header from "./Header";
import Loading from "./Loading";
import NotSupported from "./NotSupported";
import { Step } from "./Stepper";
import TwitterLink from "./social/TwitterLink";

import StateContext from "../contexts/State";
import * as functions from "../firebase/functions";
import rocketPenArt from "../images/illustrations/Rocket_Pen_White_Smoke.png";
import * as colors from "../utils/colors";
import * as constants from "../utils/constants";
import * as intercom from "../utils/intercom";
import * as routes from "../utils/routes";
import * as styleUtils from "../utils/styles";
import * as utils from "../utils/utils";

const Column = styled.div`
  max-width: 26rem;
  padding: 1rem;
  margin: 2rem auto;
  ${styleUtils.mq[2]} {
    margin: 0 auto;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Home = () => {
  const history = useHistory();
  const state = useContext(StateContext);
  const [creatingRoom, setCreatingRoom] = useState(false);

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (state.user) {
      setCreatingRoom(true);
      try {
        const createdRoomResult = await functions.createRoom({
          from: utils.getDeviceAndPluginType(),
        });
        setCreatingRoom(false);
        history.push(`${routes.ROOM_BASE}/${createdRoomResult.roomId}`);
      } catch (error) {
        sentry.captureException(error);
        setCreatingRoom(false);
      }
    } else {
      if (state.authUser?.isAnonymous) {
        state.setSignInModal("upgradeFromAnonymous");
      } else {
        state.setSignInModal("signIn");
      }
    }
  };

  if (creatingRoom) {
    return <Loading text="Cleaning the desk" />;
  }

  return (
    <FlexCol
      css={css`
        max-width: 90rem;
        margin: auto;
        height: 100%;
      `}
    >
      <Header />
      <Box
        css={css`
          margin: 1rem;
          ${styleUtils.mq[1]} {
            margin: 1rem 3rem;
          }
          ${styleUtils.mq[2]} {
            padding: 4rem 2rem;
            flex-direction: row;
          }
          ${styleUtils.mq[3]} {
            margin: 1rem 8rem;
          }
        `}
      >
        {/* LEFT */}
        <Column>
          <h2
            css={css`
              font-size: 2.2rem;
              margin-top: 0;
            `}
          >
            <RoughNotation
              type="box"
              show
              animationDelay={800}
              color={colors.GRAPIC_TURQUOISE}
            >
              Get started to share your thoughts
            </RoughNotation>
          </h2>
          <form
            onSubmit={onFormSubmit}
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;
              min-height: 10.5rem;
              justify-content: center;
            `}
          >
            {state.user ? (
              <p
                css={css`
                  margin-bottom: 0;
                `}
              >{`${state.isNewUser ? `Welcome` : `Welcome back`} ${
                state.user.displayName || state.user.email
              }!`}</p>
            ) : (
              <Fragment>
                <p
                  css={css`
                    margin-bottom: 0.5rem;
                    font-size: 0.9rem;
                  `}
                >
                  What's your name?
                </p>
                <Input
                  value={state.userName}
                  placeholder={state.placeholderUserName}
                  onChange={(event) => state.setUserName(event.target.value)}
                  css={{ width: "100%" }}
                  autoFocus
                />
              </Fragment>
            )}
            <Button
              type="submit"
              disabled={!utils.isBrowserSupported()}
              primary
              css={css`
                font-size: 1.4rem;
                font-weight: 700;
                padding: 0.7rem 2rem;
                width: 100%;
                margin-top: 1.2rem;
                height: 3.5rem;
              `}
            >
              Create room
            </Button>
            {!utils.isBrowserSupported() && <NotSupported />}
          </form>
        </Column>

        {/* RIGHT */}
        <Column>
          <h2 css={{ fontSize: "1.5rem", fontWeight: 600, marginTop: 0 }}>
            Bring your offline ideas online
          </h2>
          <p>
            {`Stream analog notebooks or whiteboards to your remote colleagues. Collaborate with up to ${constants.maxParticipants} devices in real time.`}
          </p>

          <StepWrapper>
            <Step done css={{ marginRight: "0.5rem" }}>
              1
            </Step>
            Create your room here.
          </StepWrapper>
          <StepWrapper>
            <Step done css={{ marginRight: "0.5rem" }}>
              2
            </Step>
            <span>
              {`Scan the QR code with the `}
              <ExternalLink to={appStoreUrl}>{`Grapic iOS app`}</ExternalLink>
              {`.`}
            </span>
          </StepWrapper>
          <StepWrapper>
            <Step done css={{ marginRight: "0.5rem" }}>
              3
            </Step>
            Invite colleagues and show them your ideas!
          </StepWrapper>
        </Column>
      </Box>

      <FlexCol
        css={css`
          flex: 1;
          padding: 1rem 1rem 0;
          ${styleUtils.mq[2]} {
            flex-direction: row;
          }
        `}
      >
        <div
          css={css`
            margin: 0 auto auto auto;
            padding: 2rem;
            ${styleUtils.mq[2]} {
              padding: 2rem 7rem;
            }
          `}
        >
          <div
            css={css`
              max-width: 26rem;
            `}
          >
            <h2
              css={css`
                font-size: 2rem;
              `}
            >
              Don't have the Grapic app yet?
            </h2>
            <p>
              Get the app to use analog notebooks or whiteboards in your online
              meetings.
            </p>
            <AppWaitlistCTA />
            <p
              css={css`
                margin-top: 2rem;
                font-weight: 300;
              `}
            >
              {`Missing a feature or just want to talk?`}
              <br />
              <TextButton
                css={{ textDecoration: "underline" }}
                onClick={() => intercom.show()}
              >
                {`Chat with us`}
              </TextButton>
              {` or `}
              <ExternalLink to="mailto:hi@grapic.co">
                {`send an email`}
              </ExternalLink>
              {`.`}
            </p>
            <p>
              <TwitterLink />
            </p>
          </div>
        </div>
        <Flex
          css={css`
            margin: 2rem auto 0;
            ${styleUtils.darkMode} {
              filter: contrast(2);
            }
          `}
        >
          <img
            src={rocketPenArt}
            alt="Illustration"
            loading="lazy"
            width="480"
            height="360"
            css={css`
              max-width: 100%;
              object-fit: contain;
              object-position: bottom;
              margin-top: auto;
            `}
          />
        </Flex>
      </FlexCol>
    </FlexCol>
  );
};

export default Home;
