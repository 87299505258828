import { useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import Loading from "./Loading";
import StateContext from "../contexts/State";
import * as routes from "../utils/routes";

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const state = useContext(StateContext);

  return (
    <Route
      {...rest}
      render={() =>
        !state.didLoadUser ? (
          <Loading text="Loading" />
        ) : !state.authUser ? (
          <Redirect to={routes.HOME} />
        ) : (
          <>{children}</>
        )
      }
    />
  );
};

export default PrivateRoute;
