import { Fragment, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import DocumentTitle from "react-document-title";

import Styles from "./Styles";
// import Grapics from "./components/Grapics";
// import GrapicDetails from "./components/GrapicDetails";
import Home from "./components/Home";
import New from "./components/New";
import Settings from "./components/Settings";
import SignInModal from "./components/SignInModal";
import PrivateRoute from "./components/PrivateRoute";
import Room from "./components/room/Room";
import { StateContextHolder } from "./contexts/State";
import * as analytics from "./firebase/analytics";
import { useCleanOldKeys } from "./hooks/useWithStorage";
import * as intercom from "./utils/intercom";
import * as routes from "./utils/routes";

/**
 * Logs screen_view events.
 * Made this its own component (inside DocumentTitle) to get more accurate html title reports.
 * Skipped helmet because: https://github.com/nfl/react-helmet/issues/189.
 * Logging screen_view (instead of page_view) because:
 * https://stackoverflow.com/questions/59330467/how-to-track-page-view-with-firebase-analytics-in-a-web-single-page-app
 * https://github.com/angular/angularfire/blob/0c5a1577b800082ef44b8e27aee966cfaa3082cc/docs/analytics/getting-started.md#tracking-screen-views
 */
const ScreenView = () => {
  const location = useLocation();

  useEffect(() => {
    const screen = location.pathname.startsWith(routes.ROOM_BASE)
      ? routes.ROOM_BASE
      : location.pathname.startsWith(routes.EMBED_ROOM_BASE)
      ? routes.EMBED_ROOM_BASE
      : location.pathname;
    analytics.setCurrentScreen(screen);
    analytics.logScreenViewEvent();
    // TODO: call intercom.update here according to guide:
    // https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
  }, [location.pathname]);

  return null;
};

const App = () => {
  useCleanOldKeys();

  const { boot } = useIntercom();
  const shouldHideIntercom = intercom.shouldHideIntercom();
  useEffect(() => {
    boot({ hideDefaultLauncher: shouldHideIntercom });
  }, [boot, shouldHideIntercom]);

  return (
    <Fragment>
      <SignInModal />
      <Switch>
        <Route exact path={routes.HOME}>
          <DocumentTitle title="Home | Grapic">
            <Fragment>
              <Home />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </Route>
        <Route path={routes.ROOM}>
          <DocumentTitle title="Room | Grapic">
            <Fragment>
              <Room />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </Route>
        <Route path={routes.EMBED_ROOM}>
          <DocumentTitle title="Embed Room | Grapic">
            <Fragment>
              <Room embed />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </Route>
        <Route path={routes.EMBED_FIGMA_ROOM}>
          <DocumentTitle title="Figma Room | Grapic">
            <Fragment>
              <Room embed />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </Route>
        <PrivateRoute path={routes.SETTINGS}>
          <DocumentTitle title="Account | Grapic">
            <Fragment>
              <Settings />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </PrivateRoute>
        <Route
          path={[
            routes.NEW_GRAPIC_EMBED_FIGMA,
            routes.NEW_GRAPIC_EMBED,
            routes.NEW_GRAPIC,
          ]}
        >
          <DocumentTitle title="New | Grapic">
            <Fragment>
              <New />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </Route>
        {/* <PrivateRoute path={routes.GRAPIC_DETAILS}>
          <DocumentTitle title="Grapic | Grapic">
            <Fragment>
              <GrapicDetails />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </PrivateRoute> */}
        {/* <PrivateRoute path={routes.GRAPICS}>
          <DocumentTitle title="My Grapics | Grapic">
            <Fragment>
              <Grapics />
              <ScreenView />
            </Fragment>
          </DocumentTitle>
        </PrivateRoute> */}
        <Route path="*">
          <Redirect to={routes.HOME} />
        </Route>
      </Switch>
    </Fragment>
  );
};

const AppWithRouter = () => (
  <Router>
    <StateContextHolder>
      <IntercomProvider appId={intercom.getAppId()}>
        <Styles />
        <App />
      </IntercomProvider>
    </StateContextHolder>
  </Router>
);

export default AppWithRouter;
