// react-app-polyfill: these must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./firebase/init";

import React from "react";
import ReactDOM from "react-dom";
import * as sentry from "@sentry/react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const sentryOptions: sentry.BrowserOptions = {
  dsn: "https://c16293d701774cd19dbeee6315ff56d4@o404400.ingest.sentry.io/5268011",
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
};

if (
  process.env.REACT_APP_RELEASE_ENV === "production" ||
  process.env.REACT_APP_RELEASE_ENV === "staging"
) {
  sentryOptions.environment = process.env.REACT_APP_RELEASE_ENV;
}

sentry.init(sentryOptions);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
