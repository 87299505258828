import * as colors from "../../utils/colors";

const Present = ({
  title,
  color = colors.GRAPIC_BLACK,
  ...rest
}: {
  className?: string;
  title?: string;
  color?: string;
}) => (
  <svg
    width="83"
    height="106"
    viewBox="0 0 83 106"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    {title && <title>{title}</title>}
    <path
      d="M15.918 105.09H67.7734C77.8808 105.09 82.9101 99.9629 82.9101 89.8067V45.4707C82.9101 39.1719 82.1777 36.4375 78.2714 32.4336L51.3672 5.04105C47.6563 1.23245 44.5801 0.402344 39.1113 0.402344H15.918C5.85935 0.402344 0.78125 5.57814 0.78125 15.7344V89.8067C0.78125 100.012 5.81055 105.09 15.918 105.09ZM16.2598 97.2286C11.2305 97.2286 8.64255 94.543 8.64255 89.6602V15.8809C8.64255 11.0469 11.2305 8.26375 16.3086 8.26375H38.0371V36.6328C38.0371 42.7852 41.1133 45.8125 47.2168 45.8125H75.0489V89.6602C75.0489 94.543 72.4609 97.2286 67.3827 97.2286H16.2598ZM48.0956 38.4395C46.1426 38.4395 45.4102 37.6582 45.4102 35.7051V9.77734L73.5351 38.4395H48.0956ZM45.5567 87.4141V69.6895L45.2637 61.7305L49.4141 66.0273L53.8086 70.5195C54.4922 71.252 55.4687 71.6426 56.4452 71.6426C58.3984 71.6426 59.8633 70.2266 59.8633 68.2734C59.8633 67.1504 59.4239 66.3204 58.6426 65.6367L44.5801 52.6973C43.5547 51.7695 42.7734 51.4277 41.8456 51.4277C40.9179 51.4277 40.1367 51.7695 39.1113 52.6973L25.0489 65.6367C24.2676 66.3204 23.8281 67.1504 23.8281 68.2734C23.8281 70.2266 25.2442 71.6426 27.2461 71.6426C28.1739 71.6426 29.1992 71.252 29.8828 70.5195L34.2774 66.0273L38.4277 61.7305L38.1348 69.6895V87.4141C38.1348 89.4161 39.8438 91.0274 41.8456 91.0274C43.8476 91.0274 45.5567 89.4161 45.5567 87.4141Z"
      fill={color}
    />
  </svg>
);

export default Present;
