/** @jsxImportSource @emotion/react */

import { Fragment } from "react";
import { css } from "@emotion/react";
import * as twilio from "twilio-video";

import Avatars from "./Avatars";
import { Flex, FlexCol } from "../atoms";
import Logo from "../Logo";
import * as constants from "../../utils/constants";
import * as styleUtils from "../../utils/styles";

const RoomHeader = (props: {
  embed?: boolean;
  localParticipant?: twilio.LocalParticipant;
  participants: twilio.RemoteParticipant[];
}) => {
  return (
    <Fragment>
      {!props.embed && (
        <Flex
          css={css`
            padding-top: 1rem;
            justify-content: flex-end;
            z-index: 2;
          `}
        >
          <div
            css={css`
              flex-grow: 1;
            `}
          >
            <Logo
              css={css`
                display: none;
                ${styleUtils.mq[0]} {
                  display: flex;
                }
                margin-left: 1rem;
              `}
            />
          </div>
          <Flex
            css={css`
              align-items: flex-start;
              overflow-x: auto;
              min-height: 51px; // fix for safari to prevent overflow-y scroll
            `}
          >
            {props.participants.length + 1 >= constants.maxParticipants / 2 && (
              <FlexCol
                css={css`
                  align-items: center;
                  font-size: 0.9rem;
                  margin: 0 0.5rem;
                `}
              >
                <span>{`${props.participants.length + 1} of ${
                  constants.maxParticipants
                }`}</span>
                <span>devices</span>
              </FlexCol>
            )}
            {props.localParticipant && (
              <Avatars localParticipant={props.localParticipant} />
            )}
            {/* temp removing this because it is buggy */}
            {/* {state.authUser?.isAnonymous && (
              <Button
                css={css`
                  margin-right: 1rem;
                `}
                onClick={() => state.setSignInModal("upgradeFromAnonymous")}
              >
                Sign in
              </Button>
            )} */}
          </Flex>
        </Flex>
      )}
    </Fragment>
  );
};

export default RoomHeader;
