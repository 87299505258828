/** @jsxImportSource @emotion/react */

import { Link } from "react-router-dom";
import { css } from "@emotion/react";

import { Flex } from "./atoms";
import * as routes from "../utils/routes";
import logo from "../images/logo/png/grapic-logo-noborder-512.png";

const Logo = ({ ...rest }: { className?: string }) => (
  <Flex
    css={css`
      align-items: center;
    `}
    {...rest}
  >
    <Link
      to={routes.HOME}
      css={css`
        display: flex;
        align-items: center;
        text-decoration: none;
        color: unset;
      `}
    >
      <img
        src={logo}
        alt="Grapic logo"
        loading="lazy"
        width="38"
        height="31"
        css={css`
          max-width: 100%;
          object-fit: contain;
        `}
      />
      <div
        css={css`
          margin-left: 0.8rem;
        `}
      >
        <h1
          css={css`
            font-size: 1.5rem;
            margin: 0;
            word-break: keep-all;
          `}
        >
          Grapic
        </h1>
      </div>
    </Link>
  </Flex>
);

export default Logo;
