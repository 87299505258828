import * as Twilio from "twilio-video";

import VideoTrack from "./VideoTrack";
import useTrack from "../../hooks/useTrack";

interface PublicationProps {
  publication: Twilio.LocalTrackPublication | Twilio.RemoteTrackPublication;
  participant: Twilio.RemoteParticipant;
  isUserPhoneParticipant: boolean;
  embed?: boolean;
}

const Publication = ({
  publication,
  participant,
  isUserPhoneParticipant,
  embed,
}: PublicationProps) => {
  const track = useTrack(publication);

  if (!track) return null;

  switch (track.kind) {
    case "video":
      return (
        <VideoTrack
          videoTrack={track as Twilio.RemoteVideoTrack}
          participant={participant}
          isUserPhoneParticipant={isUserPhoneParticipant}
          embed={embed}
        />
      );
    default:
      return null;
  }
};

export default Publication;
