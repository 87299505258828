import * as routes from "../utils/routes";
import * as types from "../utils/types";

const FIGMA_PLUGIN_ID = "1038086438446539988";
const FIGMA_URL = "https://www.figma.com";

/**
 * Returns true if we are running in the Figma browser (desktop app)
 * Returns false otherwse, even if we are running Figma in browser (e.g. Chrome)
 */
export const isFigmaDesktopApp = () =>
  window.navigator.userAgent.includes("Figma");

/**
 * Returns true if we are on a path that the Figma plugin should be running
 */
export const isFigmaPath = () =>
  window.location.pathname.includes(routes.EMBED_FIGMA_ROOM_BASE) ||
  window.location.pathname.includes(routes.NEW_GRAPIC_EMBED_FIGMA);

/**
 * Try to detect if we are running in the Figma plugin.
 * We can't know for sure.
 */
export const isProbablyFigma = () => isFigmaDesktopApp() || isFigmaPath();

/**
 * We only send messages to Figma if we are on a Figma path
 */
const shouldPost = () => isFigmaPath();

/**
 * Creates a HTML image element and returns it when loaded
 */
const createImage = (url: string) => {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = () => reject();
    image.crossOrigin = "anonymous";
    image.src = url;
  });
};

/**
 * Returns the image element when loaded
 *
 * TODO: use this instead
 */
const loadImage = (image: HTMLImageElement) => {
  //   return new Promise<HTMLImageElement>((resolve) => {
  //     imageElement.addEventListener("load", (event) => {
  //       const image = event.currentTarget as HTMLImageElement;
  //       resolve(image);
  //     });
  //   });
  // OR
  return new Promise<HTMLImageElement>((resolve, reject) => {
    image.onload = () => resolve(image);
    image.onerror = () => reject();
    image.crossOrigin = "anonymous";
  });
};

/**
 * Returns the bytes of a canvas
 */
const getBytesFromCanvas = (canvas: HTMLCanvasElement) => {
  return new Promise<Uint8Array>((resolve, reject) => {
    canvas.toBlob((blob) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result && typeof reader.result !== "string") {
          const byteArray = new Uint8Array(reader.result);
          resolve(byteArray);
        } else {
          console.warn("Could not read canvas");
        }
      };
      reader.onerror = () => reject(new Error("Could not read from blob"));
      if (blob) {
        reader.readAsArrayBuffer(blob);
      } else {
        console.warn("Could not create blob");
      }
    });
  });
};

/**
 * Returns the bytes of an image
 */
const getBytesFromImage = async (image: HTMLImageElement) => {
  // Create a HTML canvas
  const canvas = document.createElement("canvas");

  // Set width and height
  canvas.width = image.width;
  canvas.height = image.height;

  // Draw the image
  const ctx = canvas.getContext("2d");
  ctx?.drawImage(image, 0, 0);
  // const dataURL = canvas.toDataURL(); // "image/jpeg" to set filetype

  const bytes = await getBytesFromCanvas(canvas);
  return bytes;
};

/**
 * Figma docs: https://www.figma.com/plugin-docs/creating-ui/
 */
const postMessage = (message: types.FigmaMessage) => {
  console.log("Posting message to Figma", message);
  window.parent?.postMessage?.(
    { pluginMessage: message, pluginId: FIGMA_PLUGIN_ID },
    FIGMA_URL
  );
};

export const postImage = async (
  image: types.Firestore.GrapicImage,
  downloadUrl: string,
  imageElement?: HTMLImageElement
) => {
  if (!shouldPost()) return;

  const loadedImageElement = imageElement
    ? await loadImage(imageElement)
    : await createImage(downloadUrl);
  const bytes = await getBytesFromImage(loadedImageElement);
  // TODO: check if we can use the new getBytes() from firebase storeage instead
  // https://firebase.google.com/docs/reference/android/com/google/firebase/storage/StorageReference#getBytes(long)

  postMessage({
    type: "image",
    id: image.id,
    bytes,
    url: downloadUrl,
    width: image.size[0],
    height: image.size[1],
    createdAtMs: image.createdAt.toMillis(),
  });
};

export const postRoom = (roomId: string) => {
  if (!shouldPost()) return;
  postMessage({ type: "room", roomId });
};

export const postNotification = (message: string) => {
  if (!shouldPost()) return;
  postMessage({ type: "notification", message });
};
