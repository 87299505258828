/** @jsxImportSource @emotion/react */

import { useContext } from "react";
import Code from "qrcode.react";
import { css } from "@emotion/react";

import StateContext from "../contexts/State";
import * as routes from "../utils/routes";
import * as styleUtils from "../utils/styles";

interface QRCodeProps {
  room: string;
  localParticipantIdentity: string;
  size?: number;
}

const QRCode = ({
  room,
  localParticipantIdentity,
  size = 150,
}: QRCodeProps) => {
  const state = useContext(StateContext);

  const url = new URL(
    `${routes.iosBundleId}://${window.location.host}${routes.ROOM_BASE}/${room}`
  );
  // Room ID to be joined
  url.searchParams.append(routes.QUERY_ROOM, room);
  // The user name of the web app
  // TODO: it seems this is not used anymore, look into if we can deprecate
  url.searchParams.append(routes.QUERY_WEB_USER, localParticipantIdentity);

  // TODO: should we use clientId here instead of (or in addition to) userId?
  if (state.authUser?.uid) {
    url.searchParams.append(routes.QUERY_QR_CREATOR, state.authUser.uid);
  }

  const qrCodeValue = url.toString();

  return (
    <div
      css={css`
        display: inline-flex;
        background: white;
        border-radius: 0.5rem;
      `}
    >
      <Code
        renderAs="svg"
        value={qrCodeValue}
        size={size}
        css={css`
          background: white;
          border-radius: 0.5rem;
          ${styleUtils.darkMode} {
            margin: 2.5rem;
          }
        `}
      />
    </div>
  );
};

export default QRCode;
